import logo from './logo.svg';
// import './App.css';
  import { Route, Routes } from 'react-router-dom';
  import { routes } from './routes/routes';
import Layout from './components/layout';
// import About_Us from './pages/About_Us';
// import '../public/sass/style.scss'
import './assets/sass/style.scss'
function App() {
  return (
    <div>
        {/* <Navbar/> */}
        {/* <Provider store={store}> */}
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={route.path=="/pages/Storenotfound"?route.component:<Layout>{route.component}</Layout>}
              // element={<Layout></Layout>}
            />
          ))}
        </Routes>
        {/* </Provider> */}
      </div>
  );
}

export default App;
