import Reveal from 'react-awesome-reveal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ALink from '../../common/ALink';
import OwlCarousel from '../../features/owl-carousel';
import { fadeInUpShorter } from '../../../utils/data/keyframes'
import slider1 from "../../../assets/images/home/slider/slide1.jpg";
import slider2 from "../../../assets/images/home/slider/slide2.jpg"
import { useEffect,useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


export default function IntroSection() {
const [Banner ,setBanner]=useState([]);
     const guid=window.localStorage.getItem("Storeguid");
    //  const guid="sto-c3baea86-8051-45ac-a970-1ad0850a72d2";

    console.log('Store guid in banner Section ',guid);

    useEffect(()=>{
        const ShopBanner=JSON.parse(window.localStorage.getItem("ShopBanner"));
        console.log('Shop Banner ',ShopBanner);
        const HomeTopBanner=ShopBanner?.filter((value)=>value.bannerPosition==="Home Top");
        console.log('HomeToBanner ',HomeTopBanner);
        setBanner(HomeTopBanner)
    },[])

    return (

        <OwlCarousel adClass="home-slider owl-theme show-nav-hover nav-big" options={{
            nav: true,
            dots: false,
            loop: false,
            navText: ['<i class="icon-left-open-big">', '<i class="icon-right-open-big">']
        }}>
            {Banner.map((banner)=>(
            <div className="home-slide home-slide1 banner d-flex align-items-center">
            <Link to={`/shop?bannerGuid=${banner.guid}`}style={{ display: "block", width: "100%", height: "100%" }}>
             <LazyLoadImage
                 className="slide-bg"
                 alt="slider image"
                 src={banner.url}
             />
             </Link>
             <div className="banner-layer">
                 <Reveal keyframes={fadeInUpShorter} delay={500} duration={1000}>
                 </Reveal>
             </div>
         </div>
            ))}

            
            <div className="home-slide home-slide2 banner d-flex align-items-center">
                <LazyLoadImage
                    className="slide-bg"
                    alt="slider image"
                    src={slider2}
                />
                <div className="banner-layer text-uppercase">
                    <Reveal keyframes={fadeInUpShorter} delay={200} duration={1000}>
                        <h2 className="text-transform-none">New Season Hats </h2>
                        <h3 className="text-uppercase rotated-upto-text mb-0"><small>Up to</small>20% off</h3>

                        <hr className="short-thick-divider mb-sm-0 mb-1" />

                        <div>
                            <h5 className="text-uppercase d-inline-block mb-2 mb-sm-0">Starting at <span>₹<em>19</em>99</span></h5>
                            <ALink href="/shop" className="btn btn-dark btn-xl btn-icon-right" role="button">Shop Now <i className="fas fa-long-arrow-alt-right"></i></ALink>
                        </div>
                    </Reveal>
                </div>
            </div>
        </OwlCarousel>
    )
}