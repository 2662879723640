import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';

function CartPopup(props) {
    const { product } = props;
    console.log('product on add to cart ',product);
    console.log('Product Image Url ',product.imageUrls.length);
    return (
        <div className="minipopup-area">
            <div className="minipopup-box" style={{ top: "0" }}>
                <div className="product media-with-lazy">
                    <figure className="product-media w-100">
                        <ALink href={`/product_detail/${product.name}/${product.guid}`} 
                        state={product}
                        >
                            <LazyLoadImage
                                alt="product"
                                src={product?.imageUrls.length>0 ? `${product?.imageUrls[0]}` : ''}
                                threshold={500}
                                effect="black and white"
                                width="100%"
                                height="auto"
                            />
                        </ALink>
                    </figure>
                    <div className="product-detail">
                        {/* {
                            product.index > -1 ?
                                !product.variants[product.index].color ?
                                    <ALink className="product-name" href={`/product/default/${product.slug}`}>{product.name + ' - ' + product.variants[product.index].size.name}</ALink>
                                    :
                                    !product.variants[product.index].size ?
                                        <ALink className="product-name" href={`/product/default/${product.slug}`}>{product.name + ' - ' + product.variants[product.index].color.name}</ALink>
                                        :
                                        <ALink className="product-name" href={`/product/default/${product.slug}`}>{product.name + ' - ' + product.variants[product.index].color.name + ', ' + product.variants[product.index].size.name}</ALink>
                                :
                                <ALink className="product-name" href={`/product/default/${product.slug}`}>{product.name}</ALink>
                        } */}
                        <p>{product?.name}</p>

                        <p>has been added to your cart.</p>
                    </div>
                </div>
                <div className="product-action ">
                    <ALink href="/pages/cart" className="btn viewcart">View Cart</ALink>
                    <ALink href="/pages/checkout" className="btn btn-dark checkout " style={{ color: 'white' }}>Checkout</ALink>
                </div>
                <button className="mfp-close"></button>
            </div>
        </div>
    )
}

export default React.memo(CartPopup);