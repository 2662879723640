// src/ChangePasswordForm.js

import React, { useState } from 'react';
import axios from 'axios';
import ALink from '../../components/common/ALink';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Install react-icons if you haven't
import { baseUrl, uiUrl } from '../../config/config';

const ChangePasswordForm = () => {

    const [loading,setLoading]=useState(false);

    const inputStyle = {
        borderRadius: '5px',
        border: '2px solid #ccc',
        padding: '10px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box'
    };
    const buttonStyle = {
        borderRadius: '5px',
        padding: '10px 20px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#000',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        height:"59px",
        cursor: loading ? 'not-allowed' : 'pointer',
        opacity: loading ? 1 : undefined, // Keep the opacity same when loading
    };

    const errorStyle={
        color: 'red',
        marginBottom: '10px',
        textAlign:"center"
    }
    const form_wrapper={
        border: '2px solid #ccc',
        borderRadius: '10px',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    }
    const login_box ={
    // display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh", /* Ensure it takes the full viewport height */
    }

    const container_login_container ={
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }

    const paramdata=useParams();
    console.log('ParamData ',paramdata?.token);

    const [shopCheck,setShopCheck]=useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [passwordInfo,setPasswordInfo]=useState({
        "new-password":"",
        "confirm-new-password":"",
    })
    const [errorMessage, setErrorMessage] = useState("");

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const HandleInfo=(e)=>{
       const {id,value}=e.target;
       setPasswordInfo((prevalue)=>({
            ...prevalue,
            [id]:value
        }))
    } 
    const handleSubmit=(e)=>{
        e.preventDefault();
        setLoading(true);
        console.log('Login Info ',passwordInfo);

        const obj={
            "password":passwordInfo["new-password"],
            "confirmPassword":passwordInfo["confirm-new-password"],
            "token":paramdata["token"]
        }
        console.log("Obj",obj);
        axios.post(`${baseUrl}/v1/customer/resetPassword`,{
            "customer":{
                ...obj
            }
        },{
            "headers":{
                "service_ref":"8xuf4dev",
                "Content-Type":"application/json"
            }
        })
        .then((responce)=>{
            console.log('Responce --> ',responce);
            if(responce.data.success=true){
                setErrorMessage("");
                setLoading(false);
                 window.location.href=`${uiUrl}/pages/login`;
            }else {
                console.log(responce);
            }
        })
        .catch((error)=>{
            console.log('Error',error?.response?.data?.error?.message);
            setErrorMessage(error?.response?.data?.error?.message);
            setLoading(false);   
        })

    }
        return (
            <main className="main">
                <div className="container login-container" >
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="login-box" style={login_box}>
                                <div className='form-wrapper' style={form_wrapper}>
                                <div className="row">
                                    <div className="col-md-6 m-auto">
                                        <div className="heading mb-1">
                                            <h2 className="title text-center">Change Password</h2>
                                        </div>

                                        <form  onSubmit={handleSubmit}>
                                        {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
                                            <label htmlFor="new-password">
                                                New Password <span className="required">*</span>
                                            </label>
                                            <div className='Inputpass1' style={{ position: 'relative' }}>
                                            <input 
                                            type={passwordVisible?"text":"password"} 
                                            className="form-input form-wide" 
                                            id="new-password" required style={inputStyle} 
                                            onChange={HandleInfo}
                                            />
                                            <button 
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                                style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: 'pointer' }}
                                            >
                                                 {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                            </div>
                                            <label htmlFor="confirm-new-password">
                                            confirm  New Password <span className="required">*</span>
                                            </label>
                                            <div className='Inputpass2' style={{ position: 'relative' }}>
                                            <input 
                                            type={confirmPasswordVisible?"text":"password"} 
                                            className="form-input form-wide" 
                                            id="confirm-new-password" 
                                            required style={inputStyle} 
                                            onChange={HandleInfo}/>
                                            <button 
                                                type='button'
                                                onClick={toggleConfirmPasswordVisibility}
                                                style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', background: 'none', border: 'none', cursor: 'pointer' }}
                                            >
                                            {confirmPasswordVisible?<FaEyeSlash />:<FaEye />}
                                            </button>
                                            </div>
                                            <div >
                                                <div className="custom-control custom-checkbox" >
                                                </div>
                                                
                                            </div>
                                            <button type="submit" className="btn btn-dark btn-md w-100" style={buttonStyle} disabled={loading}>{
                                                loading?  <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', }}>
                                                <CircularProgress sx={{color:"white"}}/>
                                            </Box>:"Change Password"
                                                }</button>
                                        </form>

                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
};

export default ChangePasswordForm;
