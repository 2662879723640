import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
// import NewsletterModal from "../components/features/modals/newsletter-modal";
import{ getCartDetails , setCartDetails} from '../store/cart/cartDetailsSlice';
import IntroSection from '../components/partials/home/intro-section';
import CatySection from '../components/partials/home/caty-section';
import BannerSection from '../components/partials/home/banner-section';
import FeaturedCollection from "../components/partials/home/featured-collection";
import FeatureboxSection from "../components/partials/home/featurebox-section";
import { baseUrl } from '../config/config';
import StoreNotFound from "../pages/StoreNotFound/Storenotfound";

const Home = () => {
  const dispatch=useDispatch();

  const navigate = useNavigate();
  const [storeData, setStoreData] = useState({});
  const [storeCheck, setStoreCheck] = useState(false);
  const [loading, setLoading] = useState(true);

  dispatch(setCartDetails({ data: [] }));

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top on component mount
  }, []);

  useEffect(() => {
    const storeLink = window.location.href;
    axios.get(`${baseUrl}/v1/store/find/store?url=${storeLink}`, { headers: { 'service_ref': '8xuf4dev' } })
      .then((res) => {
        if (res.data.success === true) {
          setStoreCheck(true);
          setStoreData(res.data.data.store[0]);

          // Storing the GUID and other details in sessionStorage
          const guid = res.data.data.store[0].guid;
          window.sessionStorage.setItem("Storeguid", guid);
          window.sessionStorage.setItem("shoplogo", res.data.data.store[0].store_logo_url);
          window.sessionStorage.setItem("favicon",res.data.data.store[0].store_favicon_url);
          console.log('Responce',res?.data?.data?.store[0].store_favicon_url);
          updateFavicon()
          // Storing the banner in localStorage
          const shopBanner = JSON.stringify(res.data.data.store[0].banners);
          window.localStorage.setItem("ShopBanner", shopBanner);
        } else {
          setStoreCheck(false);
          navigate("/pages/Storenotfound")
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log('Something Went Wrong:', error);
        setLoading(false);
      });
  }, []);

  const updateFavicon = () => {
    const faviconUrl = window.sessionStorage.getItem('favicon');
    if (faviconUrl) {
      const link = document.getElementById('dynamic-favicon');
      if (link) {
        link.href = faviconUrl;
      } else {
        // Create a new link element if it does not exist
        const newLink = document.createElement('link');
        newLink.id = 'dynamic-favicon';
        newLink.rel = 'icon';
        newLink.href = faviconUrl;
        // newLink.sizes='32x32'
        document.head.appendChild(newLink);
      }
    }
  }

  return (
    <>
      {loading ? (
        <div className="loader-overlay">
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
            <span style={{ marginTop: "10px" }}>Loading</span>
          </Box>
        </div>
      ) : (
          <>
            <IntroSection />
            <section className="container">
              <h2 className="section-title ls-n-15 text-center pt-2 m-b-4">Shop By Category</h2>
              <CatySection />
            </section>
            <BannerSection />
            {/* <FeaturedCollection product={ [] } loading={ [] }/> */}
            <FeatureboxSection />
          </>
      )}

      <style jsx>{`
        .loader-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.7);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        }
      `}</style>
    </>
  );
};

export default Home;
