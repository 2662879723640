import SlideToggle from 'react-slide-toggle';
// import { useRouter } from 'next/router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { connect } from 'react-redux';
import React, { useEffect, useState,useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AddToCartPopup from "../../../features/modals/add-to-cart-popup";
import { getCartDetails , setCartDetails} from '../../../../store/cart/cartDetailsSlice';
import {  ToastContainer , toast} from 'react-toastify';
import shopLogo from "../../../../assets/images/shopDo Logo.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProductMediaOne from '../media/product-media-one';
import DOMPurify from 'dompurify';
import axios from 'axios';
// Import Custom Component

import ProductNav from '../product-nav';
import Qty from '../qty';
import ALink from '../../../common/ALink';
import { baseUrl } from '../../../../config/config';
// Import Actions
// import { actions as WishlistAction } from "../../../../store/wishlist";
// import { actions as CartAction } from "../../../../store/cart";

/////////////////////////////////////////////////Home Banner ////////////////////////////////////////////////////
function ProductDetailOne ( props ) {
    const dispatch=useDispatch();
    const router = useLocation();
    const [updatedCartItems , setUpdatedCartItems]= useState([]);
    const cartData = useSelector(getCartDetails);//featching data 
    
    // console.log('catgoryImageUrl',catgoryImageUrl);

    useEffect(() => {
        setUpdatedCartItems(cartData?.cartData?.data);
        console.log('In product in useeffect ',product);
    }, [cartData])


    const { product, adClass = "col-lg-8 col-md-6", prev, next, isNav = true, parent = ".product-single-default", isSticky = false } = props;
    console.log('product in product Detials ',props);
    const [ attrs, setAttrs ] = useState( { sizes: [], colors: [] } );
    const [ variant, setVariant ] = useState( null );
    const [ size, setSize ] = useState( null );
    const [ color, setColor ] = useState( null );
    const [ qty, setQty ] = useState( 1 );
    const [myArray, setMyArray] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [selectedIndices, setSelectedIndices] = useState({});
    const [showmore ,setShowMore]=useState(false);
    const [indexno,setIndexNo]=useState(0);
    const  [price,setPrice]=useState([]);
    const [checkStock ,setCheckStock]=useState(true);
    const [productImage ,setProductImage]=useState([]);
    const [stockQty ,setStockQty]=useState([]);
    const storeGuid=window.sessionStorage.getItem("Storeguid");
    const UserToken=window.sessionStorage.getItem("AccountToken");

    console.log('StoreGuid ',storeGuid);
    console.log('UserToken ',UserToken);
 
useEffect(() => {
    if (product?.options && product.options.length > 0 && product.available_options) {
        const initialSelectedIndices = {};
        product.available_options.forEach((item, index) => {
            if (item?.optionValue?.length > 0) {
                initialSelectedIndices[index] = 0; // Default to first option
            }
        });

        console.log('initialSelectedIndices', initialSelectedIndices);
        setSelectedIndices(initialSelectedIndices);

        // Function to find the first combination with stock quantity greater than zero
        const findValidCombination = (options, combination = [], indices = [], index = 0) => {
            if (index === options.length) {
                const optionsSelected = combination.join(" ");
                const optionsSelected2 =combination.join("  ");
                console.log('OptionsSelected ',optionsSelected);
                const matchingOption = product.options[0]?.options?.find((value) => {
                    return value.name === optionsSelected || value.name===optionsSelected2;
                });
                console.log('matchingOption',matchingOption);
                console.log('Matching Stock',matchingOption.stockQuantity);
                if (matchingOption && matchingOption.stockQuantity > 0) {
                    return { matchingOption, indices };
                }
                return null;
            }
            for (let i = 0; i < options[index].optionValue.length; i++) {
                combination[index] = options[index].optionValue[i];
                indices[index] = i;
                const result = findValidCombination(options, combination, indices, index + 1);
                if (result) {
                    return result;
                }
            }
            return null;
        };

        const validCombination = findValidCombination(product.available_options);

        console.log('DefaultSelectedOption', validCombination?.matchingOption);

        if (validCombination) {
            const { matchingOption, indices } = validCombination;
            const selectedOptionDetails = {
                variant_sf_id: matchingOption.variant_sf_id,
                name: matchingOption.name,
                price: matchingOption.price,
            };
            console.log('matching options image',matchingOption.imageUrls);
            setProductImage(matchingOption.imageUrls)
            console.log('matchingOption.price', matchingOption.price);
            setPrice(matchingOption.price);
            setSelectedOptions(selectedOptionDetails);
            setSelectedIndices(indices);
            setStockQty(matchingOption.stockQuantity)
        } else {
            setPrice(product.price);
        }
    } else {
        setPrice(product.price);
    }
}, [product]);

useEffect(() => {
    if (stockQty !== null) {
        changeQty(1);
    }
}, [stockQty]);

    // console.log('Product Image ',productImage);



    const selectOption = (optionIndex, itemIndex, e) => {
        if (e) e.preventDefault();

        console.log('product ',product)
        const updatedIndices = {
            ...selectedIndices,
            [optionIndex]: itemIndex
        };
        setSelectedIndices(updatedIndices);

        const selectedOptionValues = product.available_options.map((item, index) => {
            const itemIndex = updatedIndices[index];
            return item?.optionValue?.[itemIndex];
        });

        const optionsSelected = selectedOptionValues.join(" ")
        const optionsSelected2=selectedOptionValues.join("  ");
        console.log('optionsSelected',optionsSelected);
        console.log('selectedOptions 2 ',optionsSelected2);
        const matchingOption = product.options[0]?.options?.find((value) => {
            console.log('Value.name Check ---> ',value.name === optionsSelected );
            return value.name === optionsSelected ||value.name===optionsSelected2;
        });
        console.log('matchingOption',matchingOption)
        console.log('matchingOption.stockQuantity ',matchingOption.stockQuantity);
        setStockQty(matchingOption.stockQuantity)
            if(matchingOption.stockQuantity===0){
                setCheckStock(false);
            }else{
                setCheckStock(true)
            }
        if (matchingOption) {
            const selectedOptionDetails = {
                variant_sf_id: matchingOption.variant_sf_id,
                name: matchingOption.name,
                price: matchingOption.price,
            };
            console.log('matching Product Image',matchingOption.imageUrls);
            console.log('matchingOption.price', matchingOption.price)
            setProductImage(matchingOption.imageUrls);
            setPrice(matchingOption.price);
            setSelectedOptions(selectedOptionDetails);
        }
    };

    const isSelected = (optionIndex, itemIndex) => {
        return selectedIndices[optionIndex] === itemIndex;
    };

    console.log('selectedOptions',selectedOptions);
    console.log('selectedIndices',selectedIndices);

    function detectColor(color){
        if(color?.includes('Color' || 'COLOR')){
            return true;
        }
   }    

    useEffect( () => {
        if ( product ) {
            let priceToggle = document.querySelector( `${ parent } .price-toggle` );
            let variationToggle = document.querySelector( `${ parent } .variation-toggle` );

            if ( attrs?.sizes?.length && !size || attrs.colors.length && !color ) {
                document.querySelector( `${ parent } .shopping-cart` ) && document.querySelector( `${ parent } .shopping-cart` ).classList.add( 'disabled' );
                document.querySelector( `${ parent } .sticky-cart .add-cart` ) && document.querySelector( `${ parent } .sticky-cart .add-cart` ).classList.add( 'disabled' );
                priceToggle && ( priceToggle.classList.contains( 'expanded' ) && priceToggle.click() );
            } else {
                document.querySelector( `${ parent } .shopping-cart` ) && document.querySelector( `${ parent } .shopping-cart` ).classList.remove( 'disabled' );
                document.querySelector( `${ parent } .sticky-cart .add-cart` ) && document.querySelector( `${ parent } .sticky-cart .add-cart` ).classList.remove( 'disabled' );
                let index = product?.options?.findIndex( item => {
                    return !( item?.size && item?.size?.size !== size ) && !( item.color && item.color.name !== color );
                } );
                setVariant( { ...product?.options, id: index } );
            }

            if ( size !== null || color !== null ) {
                variationToggle && variationToggle.classList.contains( 'collapsed' ) && variationToggle.click();
            } else {
                variationToggle && variationToggle.classList.contains( 'expanded' ) && variationToggle.click();
            }
        }
    }, [ size, color ] )


   
    useEffect( () => {
        if ( variant && variant.id >= 0 ) {
            let priceToggle = document.querySelector( `${ parent } .price-toggle` );
            priceToggle && ( priceToggle.classList.contains( 'collapsed' ) && priceToggle.click() );
        }
    }, [ variant ] )

    function isInWishlist () {
        // return product && props.wishlist.findIndex( item => item.slug === product.slug ) > -1;
    }

    function showToastWithImage(product) {
        console.log('product in show toast ',product);
        const toastContent = () => (
          <div >
            <AddToCartPopup product={product}/>   
          </div>
        );
      
        toast(toastContent,{
            position:"bottom-right",
        });
      }

    function onWishlistClick ( e ) {
        e.preventDefault();
        if ( !isInWishlist() ) {
            let target = e.currentTarget;
            target.classList.add( "load-more-overlay" );
            target.classList.add( "loading" );

            setTimeout( () => {
                target.classList.remove( 'load-more-overlay' );
                target.classList.remove( 'loading' );
                // props.addToWishList( product );
                axios.post(`${baseUrl}/v1/wishlist`,{
                    wishlist: {
                     "store_guid": storeGuid,
                    "product_guid": product?.guid,
                }
                },{
                    headers:{
                        'service_ref': '8xuf4dev',
                        'Content-Type':'application/json',
                        'Authorization' :`Bearer ${UserToken}`
                    }
                })
                .then((responce)=>{
                    // router.push( '/pages/wishlist' );
                    console.log('responce',responce)
                })
                .catch((error)=>{
                    console.log('Error ',error);
                })

            }, 1000 );
        } else {
            // router.push( '/pages/wishlist' );
        }
    }

    const productImageCheck =productImage ? productImage:product.imageUrls;
    // console.log('productImageCheck',productImageCheck);
    // console.log('product From selcted Imgae ',productImage);
    // console.log('Product Imgage from main',product.imageUrls);

    //this is updated onAddCartClick Function which is changing product qty and adding same product with different varient
    function onAddCartClick(productDetails, quantity) {
        // Create the product variant array 
        console.log('Selected Options on Add To cart ',selectedOptions);
        console.log('Selected Options on Add To cart ',Object.keys(selectedOptions).length);


        const product_varient = Object.keys(selectedOptions).length===0?{
            option_value: "",
            option_price: productDetails.price,
            variant_sf_id: productDetails.sf_id,
            productImg:product.imageUrls
    }
 :{
                option_value: selectedOptions.name,
                option_price: selectedOptions.price,
                variant_sf_id: selectedOptions.variant_sf_id,
                productImg:productImage
        };
    
        // Create the product object including quantity and variants
    
           //we are going to add price from useState which we are using dynamically
        const product = { ...productDetails, qty: quantity, variants: product_varient };

        showToastWithImage(product);
    
        // Initialize updatedCartItemsCopy
        let updatedCartItemsCopy;
    
        if (!updatedCartItems || updatedCartItems.length === 0) {
            updatedCartItemsCopy = [product];
        } else {
            let productExists = false;

            updatedCartItemsCopy = updatedCartItems.map((cartItem) => {
                if (cartItem.sf_id === product.sf_id) {
                    let variantMatch = true;
                    console.log('updatedCartItems',updatedCartItems);
    
                        if (cartItem?.variants?.variant_sf_id !== product_varient.variant_sf_id) {
                            variantMatch = false;
                        }
    
                    if (variantMatch) {
                        productExists = true;
                        return { ...cartItem, qty: cartItem.qty + quantity }; // Update quantity
                    }
                }
                return cartItem;
            });
    
            // If the product is not found or variant does not match, add it to the array
            if (!productExists) {
                updatedCartItemsCopy.push(product);
            }
        }
    
        console.log('updatedCartItemsCopy',updatedCartItemsCopy)
        // Dispatch the updated cart details
        dispatch(setCartDetails({ data: updatedCartItemsCopy }));
    }
    
    //Change Qty Here based On Stock
    function changeQty ( value ) {
        console.log('Stock Check -->',stockQty);
        console.log('Stock Qty --> ',value);
        if(stockQty>=value){
            setCheckStock(true);
            setQty(value)
        }else{
            setCheckStock(false);
            // setQty( value );
        }
        
    }


    function initState () {
            setSize([]);
            setColor([]);
            setQty( 1 );
        }


    function clearVariation ( e ) {
        e.preventDefault();
        initState();
    }


    function isDisabled ( type, name ) {
        // console.log('Name ',name);
        // console.log('type ',type);
        // console.log('size ',size);
        // console.log('color ',color);
        
        // if ( type === 'color' && size ) {
        //     return !product.variants.find( variant => variant.size.size === size && variant.color.name === name );
        // } else if ( type === 'size' && color ) {
        //     return !product?.variants?.find( variant => variant.color.name === color && variant.size.size === name );
        // }
        // return false;
    }

    const truncateDescription=(desc)=>{
        const doc = new DOMParser().parseFromString(desc, 'text/html');
        // console.log("Doc In Truncate ",doc);
        const text = doc.body.textContent || "";
        // console.log("text In Truncate ",text);
        return text.split(" ").slice(0,30).join(" ")+" ";
    }

    const toggleToShowMore=()=>{
        setShowMore(!showmore);
    }

    const CreateMarkUp=(html)=>{
        // console.log('Html ',html);
        const data = { __html: DOMPurify.sanitize(html) };
        // console.log('Data ',data.__html);
        return data.__html;
    }
    
    ///send Selected Option In Product Media where it will show the media related To Selected Options
    return (
        <>

         <div className="col-lg-12 main-content pb-2">
                                <div className={`product-single-container product-single-default`}>
                                <div className='row'>
                            {productImage?<ProductMediaOne product={productImage} />:<ProductMediaOne product={product.imageUrls} />}
                                 
            {
                product &&
                <div className={ `product-single-details ${ adClass } mx-5` }>
                    <h1 className="product-title">{ product.name }</h1>

                    {
                        isNav ?
                            <ProductNav prev={ prev } next={ next } />
                            : ""
                    }

                    <div className="ratings-container">
                        <div className="product-ratings">
                            <span className="ratings" style={ { width: `${ 20 * product.avgRating }%` } }></span>
                            <span className="tooltiptext tooltip-top">{ product.avgRating }</span>
                        </div>

                        <ALink href="#" className="rating-link">( { product.reviews.length > 0 ? `${ product.reviews } Reviews` : 'There are no reviews yet.' } )</ALink>
                    </div>

                    <hr className="short-divider" />

                    <div className="price-box">
                        {
                            // product.price[ 0 ] == product.price[ 1 ] ?
                            //     <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) }</span>
                            //     : product.variants.length > 0 ?
                            //         <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) } &ndash; { '$' + product.price[ 1 ].toFixed( 2 ) }</span>
                            //         : 
                                    <>
                                        {/* <span className="old-price">{ '₹' + product.price }</span> */}
                                        <span className="new-price">{ '₹' + price }</span>
                                    </>
                        }
                    </div>

                    <div className="product-desc"  >
                        <p className='m-0'>{showmore? CreateMarkUp(product.description):CreateMarkUp(truncateDescription(product.description)) }
                        {
                            product?.description?.split(" ").length>30 &&(
                                <button className='btn p-0 bg-white' style={{textTransform:"lowercase"}} onClick={toggleToShowMore}>
                                    {showmore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    {showmore ? " Show Less" : " Show More"}
                                </button>
                            )
                        }
                        </p>
                      
                    </div>

                    <ul className="single-info-list">
                        {
                            product?.product_sku ?
                                <li>
                                    SKU: <strong>{ product?.product_sku }</strong>
                                </li>
                                : ''
                        }

                        <li>
                            {/* CATEGORY: { 
                            product?.subCategory_guid.map( ( item, index ) =>
                            (
                                <React.Fragment key={ `single-cat-${ index }` }>
                                    <strong>
                                        <ALink href={ { pathname: '/shop', query: { category: item.slug } } } className="category">{ item.name }</ALink>
                                    </strong>
                                    {/* { index < product?.subCategory_guid?.length - 1 ? ', ' : '' } */}
                                {/* </React.Fragment> */}
                            {/* ) ) */}
                            {/* } */} 
                            {/* } */}
                        </li>

                        {/* {
                            !product.tags == null && product.tags.length > 0 ?
                                <li>
                                    TAGs: { product.tags.map( ( item, index ) =>
                                    (
                                        <React.Fragment key={ `single-cat-${ index }` }>
                                            <strong>
                                                <ALink href={ { pathname: '/shop', query: { tag: item.slug } } } className="category">{ item.name }</ALink>
                                            </strong>
                                            { index < product.tags.length - 1 ? ', ' : '' }
                                        </React.Fragment>
                                    ) )
                                    }
                                </li>
                                : '' */}
                        {/* } */}
                    </ul>

                    {
                        product?.options?.length > 0 ?
                            <div className="product-filters-container">
                                {
                                    // attrs.colors.length > 0 ?
                                    //     <div className="product-single-filter d-flex align-items-center"><label>Color:</label>
                                    //         <ul className="config-size-list config-color-list config-filter-list">
                                    //             {
                                    //                 attrs.colors.map( ( item, index ) => (
                                    //                     <li key={ `filter-color-${ index }` } className={ `${ item.name === color ? 'active' : '' } ${ isDisabled( 'color', item.name ) ? 'disabled' : '' }` }>
                                    //                         {
                                    //                             item.thumb ?
                                    //                                 <a href="#" className="filter-thumb p-0" onClick={ ( e ) => selectColor( item.name, e ) }>
                                    //                                     <LazyLoadImage
                                    //                                         src={ process.env.NEXT_PUBLIC_ASSET_URI + item.thumb.url }
                                    //                                         alt='product thumb'
                                    //                                         width={ item.thumb.width }
                                    //                                         height={ item.thumb.height }
                                    //                                     />
                                    //                                 </a>
                                    //                                 :
                                    //                                 <a href="#" className="filter-color border-0"
                                    //                                     style={ { backgroundColor: item.name } } onClick={ ( e ) => selectColor( item.name, e ) }></a>
                                    //                         }</li>
                                    //                 ) )
                                    //             }
                                    //         </ul>
                                    //     </div>
                                    //     : ''
                                }
                                      {product?.available_options?.map((item, index) => (
                                        detectColor(item.option) ? (
                                            <div key={index} className="product-single-filter d-flex align-items-center">
                                                <label>Color:</label>
                                                <ul className="config-size-list d-inline-block">
                                                    {item?.optionValue?.map((item1, index2) => (
                                                        <li  key={`filter-color-${index}-${index2}`} className={isSelected(index, index2) ? 'active' : ''} >
                                                            <a href="#" className="d-flex align-items-center justify-content-center"
                                                               onClick={(e) => selectOption(index, index2, e)} disabled={true}>
                                                                {item1}
                                                            </a>
                                                            
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div key={index} className="product-single-filter d-flex align-items-center">
                                                <label>{item.option}</label>
                                                <ul className="config-size-list d-inline-block">
                                                    {item?.optionValue?.map((item1, index1) => (
                                                        <li key={`filter-size-${index}-${index1}`} className={isSelected(index, index1) ? 'active' : ''}>
                                                            <a href="#" className="d-flex align-items-center justify-content-center" 
                                                               onClick={(e) => selectOption(index, index1, e)}>
                                                                {item1}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    ))
                                }
                               

                                {
                                    <SlideToggle collapsed={ true }>
                                        { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                            <>
                                                <button className={ `d-none variation-toggle ${ toggleState.toLowerCase() }` } onClick={ onToggle }></button>
                                                <div className="product-single-filter m-0" ref={ setCollapsibleElement }>
                                                    <label></label>
                                                    {/* <a className="font1 text-uppercase clear-btn" href="#" onClick={ clearVariation }>Clear</a> */}
                                                </div>
                                            </>
                                        ) }
                                    </SlideToggle>
                                }
                            </div>
                            : ''
                    }

                    {
                        isSticky &&
                        <div className="sticky-wrapper">
                            <div className="sticky-header desktop-sticky sticky-cart d-none d-lg-block bg-white">
                                <div className="container">
                                    <div className="sticky-img mr-4 media-with-lazy">
                                        <figure className="mb-0">
                                            <LazyLoadImage
                                                src={ process.env.NEXT_PUBLIC_ASSET_URI + product.small_pictures[ 0 ].url }
                                                width="100%"
                                                height="auto"
                                                alt="Thumbnail"
                                            />
                                        </figure>
                                    </div>
                                    <div className="sticky-detail mb-0">
                                        <div className="sticky-product-name">
                                            <h2 className="product-title w-100 ls-0 mb-0">{ product.name }</h2>
                                            <div className="price-box mb-0">
                                                {
                                                    variant && variant.id >= 0 ?
                                                        ( variant.price ?
                                                            <span className="product-price">${ variant && variant.price.toFixed( 2 ) }</span>
                                                            : <span className="product-stock pt-3 d-block">{ product.is_out_of_stock ? 'Out of Stock' : `${ product.stock } in stock` }</span>
                                                        )
                                                        :

                                                        product.price[ 0 ] == product.price[ 1 ] ?
                                                            <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) }</span>
                                                            : product.variants.length > 0 ?
                                                                <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) } &ndash; { '$' + product.price[ 1 ].toFixed( 2 ) }</span>
                                                                :
                                                                <>
                                                                    <span className="old-price">{ '$' + product.price[ 1 ].toFixed( 2 ) }</span>
                                                                    <span className="new-price">{ '$' + product.price[ 0 ].toFixed( 2 ) }</span>
                                                                </>
                                                }
                                            </div>
                                        </div>
                                        <div className="ratings-container">
                                            <div className="product-ratings">
                                                <span className="ratings" style={ { width: `${ 20 * product.ratings }%` } }></span>
                                                <span className="tooltiptext tooltip-top">{ product.ratings.toFixed( 2 ) }</span>
                                            </div>

                                            <ALink href="#" className="rating-link">( { product.reviews > 0 ? `${ product.reviews } Reviews` : 'There are no reviews yet.' } )</ALink>
                                        </div>
                                    </div>

                                    <div className="product-action mt-0">
                                        <Qty max={ product.stock } value={ qty } onChangeQty={ changeQty } />

                             <a  className={ `btn btn-dark add-cart mr-2 ${ attrs.sizes.length > 0 || attrs.colors.length > 0 ? attrs.sizes.length > 0 && attrs.colors.length ? '' : 'disabled' : '' }` } title="Add To Cart" onClick={() => onAddCartClick(product,qty)}  style={{ color: 'white' }}>Add to Cart</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="product-action">
                        {
                            product?.variants?.length ?
                                <SlideToggle collapsed={ true }>
                                    { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                        <>
                                            <button className={ `d-none price-toggle ${ toggleState.toLowerCase() }` } onClick={ onToggle }></button>
                                            <div className="price-box product-filtered-price m-0" ref={ setCollapsibleElement }>
                                                {
                                                    variant && variant.id >= 0 && ( variant.price ? variant.sale_price ?
                                                        <>
                                                            <del className="old-price"><span>${ variant.price.toFixed( 2 ) }</span></del>
                                                            <span className="product-price">${ variant && variant.sale_price.toFixed( 2 ) }</span>
                                                        </>
                                                        : <span className="product-price">${ variant && variant.price.toFixed( 2 ) }</span>
                                                        : <span className="product-stock pb-3 d-block">{ product.is_out_of_stock ? 'Out of Stock' : `${ product.stock } in stock` }</span> )
                                                }

                                            </div>
                                        </>
                                    ) }
                                </SlideToggle>
                                : ''
                        }

                        <Qty max={ product.stock } value={ qty } onChangeQty={ changeQty } /> 

                      
                       {checkStock?<a className={ `btn btn-dark add-cart shopping-cart mr-2 ${ attrs?.sizes?.length > 0 || attrs?.colors?.length > 0 ? 'disabled' : '' }` } title="Add To Cart" onClick={()=>onAddCartClick(product,qty)}  style={{ color: 'white' }}>Add to Cart </a>:<a className={ `btn btn-dark add-cart shopping-cart mr-2 disabled` } title="Out Of Stock"   style={{ color: 'white' }}>Out Of Stock </a>} 
                    </div>

                    <hr className="divider mb-0 mt-0" />

                    {/* <div className="product-single-share mb-3">
                        <label className="sr-only">Share:</label>

                        <div className="social-icons mr-2">
                            <ALink href="#" className="social-icon social-facebook icon-facebook" title="Facebook"></ALink>
                            <ALink href="#" className="social-icon social-twitter icon-twitter"
                                title="Twitter"></ALink>
                            <ALink href="#" className="social-icon social-linkedin fab fa-linkedin-in"
                                title="Linkedin"></ALink>
                            <ALink href="#" className="social-icon social-mail icon-mail-alt"
                                title="Mail"></ALink>
                        </div>
*/
                        <a href="#" className={ `btn-icon-wish add-wishlist ${ isInWishlist() ? 'added-wishlist' : '' }` } onClick={ onWishlistClick } title={ `${ isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist' }` }><i
                            className="icon-wishlist-2"></i><span>{ isInWishlist() ? 'Go to Wishlist' : 'Add to Wishlist' }</span></a>
                   }
                    
                </div>
                
            }
              </div>
            </div>
             </div>
             <ToastContainer/>
        </>
    )
}

// const mapStateToProps = ( state ) => {
//     return {
//         wishlist: state.wishlist.list ? state.wishlist.list : []
//     }
// }

export default ProductDetailOne ;