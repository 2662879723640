import React, { useEffect, useState } from 'react';
// import { connect } from 'react-redux';
// import { useRouter } from 'next/router';

// Import Actions
// import { actions as CartAction } from "../../../store/cart";

// Import Custom Component
import ALink from '../ALink';
import { useDispatch, useSelector } from 'react-redux';
import { getCartDetails, setCartDetails } from '../../../store/cart/cartDetailsSlice';
// Import Utils
import { getCartTotal } from '../../../utils';
import { useLocation } from 'react-router-dom';

function CartMenu(props) {
    const dispatch=useDispatch();
   const [cartItems ,setcartItems]=useState([]);
   const cartData = useSelector(getCartDetails);

   useEffect(() => {
    // Check if cartData.cartData.data exists and is an array
    if (cartData?.cartData?.data && Array.isArray(cartData.cartData.data)) {
        // Update cartItems state with the new data
        setcartItems(cartData.cartData.data);
    }
}, [cartData]);

 console.log('cartItems',cartItems);


    function toggleCart(e) {
        e.preventDefault();
        document.querySelector('body').classList.toggle('cart-opened');
    }

    function cartClose() {
        document.querySelector('body').classList.contains('cart-opened') && document.querySelector('body').classList.remove('cart-opened');
    }

    function getQtyTotal(items) {
        console.log('item Length ',items.length);
        // let total = 0;
        // if (items) {
        //     for (let i = 0; i < items.length; i++) {
        //         total += parseInt(items[i].qty, 10);
        //     }
        // }
        // return total;
        return items.length;
    }

    function removeFromCart(e, cart) {
        e.preventDefault();
        console.log('cart ', cart);
        console.log('cartItems', cartItems);
    
        // Filter out the cart item with the specified id
        const updatedCartItems = cartItems.filter(item => item.sf_id !== cart.sf_id);
        console.log('updatedCartItems', updatedCartItems);
        setcartItems(updatedCartItems);
        dispatch(setCartDetails({data:updatedCartItems}))
    }
    
    function getCartTotal(items) {
        let total = 0;
        if (items) {
            for (let i = 0; i < items.length; i++) {
                console.log('Item ',items);
                console.log('Itmes Price ',items[i].variants.option_price);
                console.log('Itme Qty ',items[i].qty);
                total += parseInt(items[i].variants.option_price * items[i].qty, 10);
            }
        }
    
        return total;
    }

    return (
        <div className="dropdown cart-dropdown">
            <a href="#" title="Cart" className="dropdown-toggle dropdown-arrow cart-toggle" onClick={toggleCart}>
                <i className="icon-cart-thick"></i>
                <span className="cart-count badge-circle">{getQtyTotal(cartItems)}</span>
            </a>

            <div className="cart-overlay" onClick={cartClose}></div>

            <div className="dropdown-menu mobile-cart">
                <a href="#" title="Close (Esc)" className="btn-close" onClick={e => { cartClose(); e.preventDefault(); }}>×</a>

                <div className="dropdownmenu-wrapper">
                    <div className="dropdown-cart-header">Shopping Cart</div>

                    {
                        cartItems?.length > 0 ?
                            <>
                                <div className="dropdown-cart-products">
                                    {
                                        cartItems.map((cart, index) => (
                                            <div className="product" key={"cartItems" + index}>
                                                <div className="product-details">
                                                    <h2 className="product-title">
                                                        {
                                                            cart.index > -1 ?
                                                                !cart.variants[cart.index].color ?
                                                                    <ALink href={`/product/default/${cart.slug}`}>{cart.name + ' - ' + cart.variants[cart.index].size.name}</ALink>
                                                                    :
                                                                    !cart.variants[cart.index].size ?
                                                                        <ALink href={`/product/default/${cart.slug}`}>{cart.name + ' - ' + cart.variants[cart.index].color.name}</ALink>
                                                                        :
                                                                        <ALink href={`/product/default/${cart.slug}`}>{cart.name + ' - ' + cart.variants[cart.index].color.name + ', ' + cart.variants[cart.index].size.name}</ALink>
                                                                :
                                                                <ALink href={`/product_detail/${cart.name}/${cart.guid}`} 
                                                                state={cart}
                                                                >{cart.name}</ALink>
                                                        }
                                                    </h2>

                                                    <span className="cart-product-info">
                                                        <span className="cart-product-qty">{cart.qty}</span> ×  ₹{cart.variants.option_price}
                                                    </span>
                                                </div>

                                                <figure className="product-image-container">
                                                    <ALink href={`/product_detail/${cart.name}/${cart.guid}`} className="product-image" 
                                                    state={cart}
                                                    >
                                                        <img src={cartItems[index].imageUrls[0]} width="78" height="78" alt="product" />
                                                    </ALink>
                                                    <a href="#" className="btn-remove icon-cancel" title="Remove Product" onClick={e => { removeFromCart(e, cart); }}></a>
                                                </figure>
                                            </div>
                                        ))
                                    }
                                </div>

                                <div className="dropdown-cart-total">
                                    <span>SUBTOTAL:</span>

                                    <span className="cart-total-price float-right">₹{getCartTotal(cartItems).toFixed(2)}</span>
                                </div>

                                <div className="dropdown-cart-action">
                                    <ALink href="/pages/cart" className="btn btn-gray btn-block view-cart">View Cart</ALink>
                                    <ALink href="/pages/checkout" className="btn btn-dark btn-block text-white">Checkout</ALink>
                                </div>
                            </>
                            :
                            <p className="pt-3 mt-2">No products in the cart.</p>
                    }
                </div>
            </div>
        </div >
    );
}

function mapStateToProps(state) {
    return {
        cartItems: state.cartlist.cart ? state.cartlist.cart : []
    }
}

export default CartMenu;