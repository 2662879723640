import { useLocation } from "react-router-dom";
// Import Custom Component
import ALink from "./ALink";
import CartMenu from "./partials/cart-menu";
import MainMenu from "./partials/main-menu";
import SearchForm from "./partials/search-form";
import logoBlack from "../../assets/images/logo-black.png"
import dummyShopLogo  from "../../assets/images/dummylogo.jpg";
import { useEffect, useState } from "react";

export default function Header ( { adClass = '' } ) {
    const location = useLocation();
    const [shoplogo,setShopLogo]=useState(dummyShopLogo);
    const[UserInfoToken,setUserInfoToken]=useState([]);

    const UserToken=window.sessionStorage.getItem("AccountToken");
    console.log("UserToken ",UserToken);
useEffect(()=>{
    const shoplogocheck=window.sessionStorage.getItem('shoplogo');
    console.log("shoplogocheck----> ",shoplogocheck)
    setShopLogo(shoplogocheck);
},[shoplogo])

console.log('shop logo in header -------> ',shoplogo);

    function openMobileMenu ( e ) {
        e.preventDefault();
        // document.querySelector( "body" ).classList.toggle( "mmenu-active" );
        e.currentTarget.classList.toggle( "active" );
    }

    // <MainMenu /> 
    return (
        <header className={ `header  ${ window.location.pathname === '/' ? 'header-transparent' : '' } ${ adClass } ` }>
            <div className="header-middle sticky-header">
                <div className="container">
                    <div className="header-left">
                        <ALink href="/" className="logo">
                            <img src={shoplogo} alt="Porto Logo" width="101" height="40" />
                        </ALink>
                        <button className="mobile-menu-toggler" type="button" onClick={ openMobileMenu }>
                            <i className="fas fa-bars"></i>
                        </button>
                        <MainMenu />

                        {/* <ALink href="/aboutUs" className="logo">
                            <img src="images/logo-black.png" alt="Porto Logo" width="101" height="40" />
                              
                        </ALink> */}
                     
                    </div>

                    <div className="header-right">

                        {/* <SearchForm /> */}
                        {UserToken?
                        <ALink href="/pages/account" className="header-icon header-icon-user"><i className="icon-user-2"></i></ALink>
                        :<ALink href="/pages/login" className="header-icon header-icon-user"><i className="icon-user-2"></i></ALink>
                        }
                        <ALink href="/pages/wishlist" className="header-icon header-icon-wishlist"><i className="icon-wishlist-2"></i></ALink>

                        <CartMenu />
                    </div>

                </div>
            </div>
        </header >
    )
}