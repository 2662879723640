import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useRouter } from 'next/router';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CountDown from "react-countdown";
import {getCartDetails,setCartDetails,} from "../../../store/cart/cartDetailsSlice";
import Home from "../../../pages/Home";
import { actions } from "../../../store/modal";
import AddToCartPopup from "../../../components/features/modals/add-to-cart-popup";
import { ToastContainer, toast } from "react-toastify";
// Import Actions

// Import Custom Component
import ALink from "../../common/ALink";
import { data, isEmptyObject } from "jquery";

function ProductOne(props) {
  const dispatch = useDispatch();
  const [updatedCartItems, setUpdatedCartItems] = useState([]);
  const cartData = useSelector(getCartDetails);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedIndices, setSelectedIndices] = useState({});
  const [price ,setPrice]=useState([]);

  useEffect(() => {
    setUpdatedCartItems(cartData?.cartData?.data);
  }, [cartData]);


  // const router = useRouter();
  const { adClass = "", link = "default", product } = props;
  console.log('Product ',product);

  useEffect(()=>{
    const priceCheck=product.options===null?product.price:product.options[0].options[0].price;
    setPrice(priceCheck);
    console.log('Price Check Product Name -->',priceCheck ,product.name);
  },[product])


  function isSale() {
    return product.price[0] !== product.price[1] &&
      product.variants.length === 0
      ? "-" +
          (
            (100 * (product.price[1] - product.price[0])) /
            product.price[1]
          ).toFixed(0) +
          "%"
      : product?.variants?.find((variant) => variant.sale_price)
      ? "Sale"
      : false;
  }

  function isInWishlist() {
    // return product && props.wishlist?.findIndex(item => item.slug === product.slug) > -1;
  }

  function onWishlistClick(e) {
    e.preventDefault();
    if (!isInWishlist()) {
      let target = e.currentTarget;
      target.classList.add("load-more-overlay");
      target.classList.add("loading");

      setTimeout(() => {
        target.classList.remove("load-more-overlay");
        target.classList.remove("loading");
        props.addToWishList(product);
      }, 1000);
    } else {
      // <Route exact path="/pages/wishlist" component={Home} />
    }
  }


  function showToastWithImage(product) {
    console.log("product in show toast ", product);
    const toastContent = () => (
      <div>
        <AddToCartPopup product={product} />
      </div>
    );

    toast(toastContent);
  }

  //this is updated onAddCartClick Function Which is responsible to add product on cart with adding the default varient to product
function onAddCartClick(productDetails) {
  console.log('Prodcut Detalis',productDetails);
        // Create the product variant array 
        console.log(typeof selectedOptions);
        const product_varient =productDetails.options!=null?{
          option_value: productDetails.options[0].options[0].name,
          option_price: productDetails.options[0].options[0].price,
          variant_sf_id: productDetails.options[0].options[0].variant_sf_id,
          
        }:{
          option_value: "",
          option_price: productDetails.price,
          variant_sf_id: productDetails.sf_id,
        };
        console.log('product_varient',product_varient);
        // Create the product object including quantity and variants
    
           //we are going to add price from useState which we are using dynamically
        const product = { ...productDetails, qty: 1, variants: product_varient };
        showToastWithImage(product);
    
        // Initialize updatedCartItemsCopy
        let updatedCartItemsCopy;
    
        if (!updatedCartItems || updatedCartItems.length === 0) {
            updatedCartItemsCopy = [product];
        } else {
            let productExists = false;
    
            updatedCartItemsCopy = updatedCartItems.map((cartItem) => {
                if (cartItem.sf_id === product.sf_id) {
                    let variantMatch = true;
                        if (cartItem?.variants?.variant_sf_id !== product_varient?.variant_sf_id) {
                            variantMatch = false;
                        }
                        console.log('variantMatch',variantMatch);
                    if (variantMatch) {
                        productExists = true;
                        return { ...cartItem, qty: cartItem.qty + product.qty }; // Update quantity
                    }
                }
                return cartItem;
            });
    
            // If the product is not found or variant does not match, add it to the array
            if (!productExists) {
                updatedCartItemsCopy.push(product);
            }
        }
        console.log('UpdateCart Item copy ',updatedCartItemsCopy);
        // Dispatch the updated cart details
         dispatch(setCartDetails({ data: updatedCartItemsCopy }));
}

function onQuickViewClick(e) {
    e.preventDefault();
    console.log("product", product);
    console.log("actions ", actions);
    actions.showQuickView(product.guid);

    // .showQuickView(product);
  }

  const onClickProduct = () => {};

  return (
    <div
      className={`product-default inner-quickview inner-icon media-with-lazy ${adClass}`}
    >
      <figure>
        {/* <ALink href={`/product/${link}/${product.slug}`}> */}
        <ALink
          href={`/product_detail/${product.name}/${product.guid}`}
          state={product}
          onClick={() => {
            onClickProduct();
          }}
        >
          <div className="lazy-overlay"></div>

          <LazyLoadImage
            alt="product"
            src={product.imageUrls != null ? product.imageUrls[0] : ""}
            threshold={500}
            effect="black and white"
            width="100%"
            height="auto"
          />
          {product.imageUrls != null ? (
            product.imageUrls.length >= 2 ? (
              <LazyLoadImage
                alt="product"
                src={product.imageUrls != null ? product.imageUrls[1] : ""}
                threshold={500}
                effect="black and white"
                wrapperClassName="product-image-hover"
              />
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </ALink>

        <div className="label-group">
          {product.is_hot ? (
            <div className="product-label label-hot">HOT</div>
          ) : (
            ""
          )}

          {isSale() ? (
            <div className="product-label label-sale">{isSale()}</div>
          ) : (
            ""
          )}
        </div>

        <div className="btn-icon-group">
          {product?.variants?.length > 0 ? (
            <ALink
              href={`/product/default/${product.slug}`}
              className="btn-icon btn-add-cart"
            >
              <i className="fa fa-arrow-right"></i>
            </ALink>
          ) : (
            // <a
            //   href="#"
            //   className="btn-icon btn-add-cart product-type-simple"
            //   title="Add To Cart"
            //   onClick={() => onAddCartClick(product)}
            // >
            //   <i className="icon-shopping-cart"></i>
            // </a>
            ""
          )}
        </div>

        {product.until && product.until !== null && (
          <CountDown product={product} />
        )}

        {/* <a href="#"className="btn-quickview"title="Quick View" onClick={onQuickViewClick}>
          Quick View
        </a> */}
      </figure>

      <div className="product-details">
        <div className="category-wrap">
          <div className="category-list">
            {product.categories
              ? product.categories.map((item, index) => (
                  <React.Fragment key={item.slug + "-" + index}>
                    <ALink
                      href={{
                        pathname: "/shop",
                        query: { category: item.slug },
                      }}
                    >
                      {item.name}
                    </ALink>
                    {index < product.categories.length - 1 ? ", " : ""}
                  </React.Fragment>
                ))
              : ""}
          </div>

          {/* <a href="#"className={`btn-icon-wish ${isInWishlist() ? "added-wishlist" : ""}`}onClick={onWishlistClick}
            title={`${isInWishlist() === true ? "Go to Wishlist" : "Add to Wishlist"}`}>
            <i className="icon-heart"></i>
          </a> */}
        </div>

        <h3 className="product-title">
          {/* <ALink href={`/product/default/${product.slug}`}>{product.name}</ALink> */}
          <ALink
            onClick={() => {
              onClickProduct();
            }}
          >
            {product.name}
          </ALink>
        </h3>

        {/* <div className="ratings-container">
                    <div className="product-ratings">
                        <span className="ratings" style={{ width: 20 * product.ratings + '%' }}></span>
                        <span className="tooltiptext tooltip-top">{product.ratings.toFixed(2)}</span>
                    </div>
                </div> */}

        <div className="price-box">
          <span className="product-price">{"₹" + price}</span>
          {/* {
                        product.price[0] == product.price[1] ?
                            <span className="product-price">{'$' + product.price[0].toFixed(2)}</span>
                            : product.variants.length > 0 ?
                                <span className="product-price">{'$' + product.price[0].toFixed(2)} &ndash; {'$' + product.price[1].toFixed(2)}</span>
                                : <>
                                    <span className="old-price">{'$' + product.price[1].toFixed(2)}</span>
                                    <span className="product-price">{'$' + product.price[0].toFixed(2)}</span>
                                </>
                    } */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : [],
  };
};

export default ProductOne;
