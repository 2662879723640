import axios from "axios";
import ALink from "../../components/common/ALink";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useState } from "react";
import {baseUrl, uiUrl} from "../../config/config"

export default function Login() {
    const [loading,setLoading]=useState(false);

    const inputStyle = {
        borderRadius: '5px',
        border: '2px solid #ccc',
        padding: '10px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box'
    };
    const buttonStyle = {
        borderRadius: '5px',
        cursor: loading ? 'not-allowed' : 'pointer',
        padding: '10px 20px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#000',
        color: '#fff',
        border: 'none',
        height:"59px",
        opacity: loading ? 1 : undefined, // Keep the opacity same when loading
    };

    const footerTextStyle = {
        marginTop: '10px',
        textAlign: 'center'
    };

    const errorStyle={
        color: 'red',
        marginBottom: '10px',
        textAlign:"center"
    }

    const [loginInfo,setLoginInfo]=useState({
        "login-email":"",
        "login-password":"",
    })

    const [errorMessage, setErrorMessage] = useState("");

    const HandleInfo=(e)=>{
        setLoading(false);
       const {id,value}=e.target;
        setLoginInfo((prevalue)=>({
            ...prevalue,
            [id]:value
        }))
    } 
    const handleSubmit=(e)=>{
        setLoading(true);
        e.preventDefault();
        console.log('Login Info ',loginInfo);

        const obj={
            "email":loginInfo["login-email"],
            "password":loginInfo["login-password"]
        }

        axios.post(`${baseUrl}/v1/customer/login`,{
            "customer":{
                ...obj
            }
        },{
            "headers":{
                "service_ref":"8xuf4dev",
                "Content-Type":"application/json"
            }
        })
        .then((responce)=>{
            console.log('Responce --> ',responce);
            console.log('Token Check ---> ',responce.data.data.token);
            window.sessionStorage.setItem("AccountToken",responce?.data?.data?.token);
            window.sessionStorage.setItem("CustomerGuid",responce?.data?.data?.Customer?.guid);
            if(responce.data.success===true){
                setErrorMessage("");    
                    window.location.href=`${uiUrl}`;
                setLoading(false);
            }else {
                console.log(responce);
            }
        })
        .catch((error)=>{
            console.log('Error',error?.response?.data?.error?.message);
            setErrorMessage(error?.response?.data?.error?.message);
              setLoading(false);
        })

    }




    return (
        <main className="main">
              <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                                <li className="breadcrumb-item"><ALink href="/shop">Shop</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    My Account
								</li>
                            </ol>
                        </div>
                    </nav>

                    <h1>My Account</h1>
                </div>
            </div>

            <div className="container login-container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="login-box">
                            <div className="row">
                                <div className="col-md-6 m-auto">
                                    <div className="heading mb-1">
                                        <h2 className="title text-center">Login</h2>
                                    </div>

                                    <form  onSubmit={handleSubmit}>
                                    {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
                                        <label htmlFor="login-email">
                                            Email address <span className="required">*</span>
                                        </label>
                                        <input type="email" className="form-input form-wide" id="login-email" required style={inputStyle} onClick={HandleInfo} onChange={HandleInfo} />

                                        <label htmlFor="login-password">
                                            Password <span className="required">*</span>
                                        </label>
                                        <input type="password" className="form-input form-wide" id="login-password" required style={inputStyle}  onChange={HandleInfo}/>
                                        <div >
                                            <div className="custom-control custom-checkbox" >
                                                <table>
                                                    <tr>
                                                        <td>
                                                <input type="checkbox" className="custom-control-input" id="remember-me" />
                                                <label className="custom-control-label mb-0" htmlFor="remember-me">Remember me</label>
                                                </td><td style={{paddingLeft:"288px"}}>
                                                <ALink href="/pages/forgotpassword" className="forget-password text-dark">
                                                Forgot Password?
                                            </ALink>
                                            </td>
                                            </tr>
                                            </table>
                                            </div>
                                            
                                        </div>
                                        <button type="submit" className="btn btn-dark btn-md w-100" style={buttonStyle} disabled={loading}>{
                                            loading?  <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', }}>
                                            <CircularProgress sx={{color:"white"}}/>
                                          </Box>:"LOGIN"
                                            }</button>
                                        <div style={footerTextStyle}>Don't Have Account? <ALink href="/pages/signUp">Sign Up</ALink></div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
