import React, { useState } from 'react';
import ALink from '../common/ALink';

function Paginations({ totalPages ,OnChangePagerNumber}) {
     console.log('Total Pages:', totalPages);
    const [page,setPage]=useState(1);
    const query = {};
    let indexsToShow = [];

    for (let i = 0; i < Math.min(totalPages, 3); i++) {
        if (page < 4 || page > totalPages - 3) {
            if (page < 4) {
                indexsToShow[i] = i + 1;
            }

            if (totalPages > 4 && page > totalPages - 3) {
                indexsToShow[i] = totalPages - 2 + i;
            }
        } else {
            indexsToShow[i] = page - 1 + i;
        }
    }
    console.log('Pages to Show:', indexsToShow);

    const handlePageChange=(value)=>{
         setPage(value)
         OnChangePagerNumber(value)
    }

    const handlePageChange2=(value)=>{
        const pageValue=value==="Right Arrow"?page+1:page-1;
        console.log('Page Value in handle page change 2 ',pageValue);
        setPage(pageValue)
        OnChangePagerNumber(pageValue);

    }
    
    return (
        <>
            {totalPages > 1 &&
                <ul className="pagination toolbox-item">
                    {
                        page > 1 &&
                        <li className="page-item">
                            <ALink className="page-link page-link-btn" href={''} onClick={()=>handlePageChange2("Left Arrow")} scroll={false}>
                                <i className="icon-angle-left"></i>
                            </ALink>
                        </li>
                    }

                    {
                        indexsToShow.map(item => (
                            <li className={`page-item ${page === item ? 'active' : ''}`} key={`page-${item}`}>
                                <ALink className="page-link" href={''}  onClick={()=>handlePageChange(item)} scroll={false}>
                                    {item}{page === item && <span className="sr-only">(current)</span>}
                                </ALink>
                            </li>
                        ))
                    }

                    {
                        page < totalPages &&
                        <li className="page-item">
                            <ALink className="page-link page-link-btn" href={''} onClick={()=>handlePageChange2("Right Arrow")} scroll={false}>
                                <i className="icon-angle-right"></i>
                            </ALink>
                        </li>
                    }
                </ul>
            }
        </>
    );
}

export default React.memo(Paginations);
