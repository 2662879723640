import React from 'react';
import { useState ,useEffect} from 'react';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';

import { useHistory, useLocation } from 'react-router-dom';
import ShopBanner from "../components/partials/shop/shop-banner"
import ShopSidebarOne from '../components/partials/shop/sidebar/shop-sidebar-one';
import Pagination from '../components/features/pagination';
 import ProductsGrid from '../components/partials/products-collection/product-grid';
// import ShopBanner from '../components/partials/shop/shop-banner';
import ALink from '../components/common/ALink';
import axios from 'axios';
import { baseUrl } from '../config/config';

const Shop = () => {
// i can fetch data from state from intro-section component then store in use state 
const location = useLocation();
const [products , setProducts] = useState([]); 
const query={};
const [productsState, setProductsState] = useState({ data: null, loading: false, error: null });
const { data, loading, error } = productsState;
const [selectedPriceRange, setSelectedPriceRange] = useState({min:"", max:""});
const router={};
const [ perPage, setPerPage ] = useState( 5 );
const [sortBy, setSortBy] = useState('default');
const [categoryObj , setCategoryObj] = useState();
const [categoryGuids, setCategoryGuids] = useState([]);
const [subCategoryGuids, setSubCategoryGuids] = useState([]);
const [totalPage ,setTotalPage]=useState([]);
const [PageNumber ,setPageNumber]=useState([]);
// const totalPage = data ? parseInt( data.products.total / perPage ) + ( data.products.total % perPage ? 1 : 0 ) : 2;

  const guid=window.sessionStorage.getItem("Storeguid");
    // const guid="sto-ed0c94df-b289-4105-8a05-c909af239176";
console.log('STore Guid ',guid);
const params= new URLSearchParams(window.location.search);
const bannerguid=params.get('bannerGuid');
const userInfoToken=window.sessionStorage.getItem("AccountToken");
console.log('UserInfoToken --->',userInfoToken);
useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top on component mount
  }, []);
  

useEffect(() => {
if(bannerguid){
axios.get(`${baseUrl}/v1/product/${bannerguid}/banner/products?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}` , { headers: { 'service_ref': '8xuf4dev'}})
.then((response)=>{
    console.log('Responce From related product ',response);
    const responseData = response.data;
    console.log('Responce Data ',responseData);
    if(sortBy === "price"){
        const priceLowToHigh = responseData.data.products.sort((a,b)=>{
            return a.price - b.price;
        })
        console.log("price low to high", priceLowToHigh);
        setProducts(priceLowToHigh)
    }else if(sortBy === "price-desc"){
        const priceHighToLow = responseData.data.products.sort((a,b)=>{
            return b.price - a.price;
        })
        console.log("priceHigh to low", priceHighToLow);
        setProducts(priceHighToLow)
    }else if(sortBy==='default'){
        setProducts(responseData.data.products);
    }
}) 
}else{
    axios.get(`${baseUrl}/v1/product/store/${guid}?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}&limit=${perPage}&page=${PageNumber}` , { headers: { 'service_ref': '8xuf4dev'}})
        .then(response => {
            // Access the response data
            const responseData = response.data;
            console.log('Responcedata ',responseData)
            console.log('Responce Data ',responseData.data.pagination);
            console.log('Responce Data totalPages',responseData.data["pagination"]["totalPages"]);
            console.log('Responce Data ',responseData.data["pagination"]["limit"]);
            console.log('Responce Data total Product ',responseData.data["pagination"]["total"]);

            setTotalPage(responseData.data["pagination"]["totalPages"])
            // console.log('Responce Data ',responseData.data);

            if(sortBy === "price"){
                const priceLowToHigh = responseData.data.products.sort((a,b)=>{
                    return a.price - b.price;
                })
                console.log("price low to high", priceLowToHigh);
                setProducts(priceLowToHigh)
            }else if(sortBy === "price-desc"){
                const priceHighToLow = responseData.data.products.sort((a,b)=>{
                    return b.price - a.price;
                })
                console.log("priceHigh to low", priceHighToLow);
                setProducts(priceHighToLow)
            }else if(sortBy==='default'){
                setProducts(responseData.data.products);
            }
        })
        .catch(error => {
            // Handle any errors
        });
    }
    
}, [selectedPriceRange , sortBy ,perPage ,PageNumber])


useEffect(() => {
if(bannerguid){ 
    axios.get(`${baseUrl}/v1/product/${bannerguid}/banner/products?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}` , { headers: { 'service_ref': '8xuf4dev'}})
    .then((response)=>{
        const responseData = response.data;  
        console.log('responseData',responseData)
        setProducts(responseData.data.products)

    });
}else{
    //same goes for this also do the same 
    const categoryGuidsParam = JSON.stringify(categoryGuids); // Convert to JSON string
    const subCategoryGuidsParam = JSON.stringify(subCategoryGuids); // Convert to JSON string
    axios.get(`${baseUrl}/v1/product/store/${guid}?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}&categoryGuids=${encodeURIComponent(categoryGuidsParam)}&subcategoryGuids=${encodeURIComponent(subCategoryGuidsParam)}` , { headers: { 'service_ref': '8xuf4dev'}})
        .then(response => {
            // Access the response data
            const responseData = response.data;
            console.log('responseData',responseData)
            setProducts(responseData.data.products)
            // Process the response data here
        })  
        .catch(error => {
            // Handle any errors
        });
    }
}, [selectedPriceRange ,categoryGuids , subCategoryGuids])


    function onPerPageChange ( e ) {
        console.log('e.target.value ',e.target.value);
      setPerPage( e.target.value );
      //need to comment this code 
  }

  function onSortByChange ( e ) {
    //need to comment this also
    //   router.push( {
    //       pathname: router.pathname,
    //       query: {
    //           ...query,
    //           sortBy: e.target.value,
    //           page: 1
    //       }
    //   } )
      //not this 
      console.log('e.target.value',e.target.value);
      setSortBy( e.target.value );
  }


  function sidebarToggle ( e ) {
      let body = document.querySelector( 'body' );
      e.preventDefault();
      if ( body.classList.contains( 'sidebar-opened' ) ) {
          body.classList.remove( 'sidebar-opened' );
      } else {
          body.classList.add( 'sidebar-opened' );
      }
  }

  function getProductPriceRange(data){

    setSelectedPriceRange(data );
}

function handlePageNumber(value){
    console.log('page Number  in shop ',value);
     setPageNumber(value)
}

function getCategoryProducts(category){
    console.log("category that are selected", category);
    setCategoryObj(category)
    if (category.catGuid) {
        // It's a main category
        if (!categoryGuids.includes(category.catGuid)) {
            setCategoryGuids([ category.catGuid]);
        }
        setSubCategoryGuids([]); // Reset subcategory because we're at top level
    } else if (category.subCategory && category.subCategory.parentCatGuid) {
        // It's a subcategory
        if (!categoryGuids.includes(category.subCategory.parentCatGuid)) {
            setCategoryGuids([category.subCategory.parentCatGuid]);
        }
        if (!subCategoryGuids.includes(category.subCategory.subCatGuid)) {
            setSubCategoryGuids([category.subCategory.subCatGuid]);
        }
    }
}

  return (
    <main className="main">
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <div className="container">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><ALink href="/">home</ALink></li>
                {
                    query.category ?
                        <>
                            <li className="breadcrumb-item"><ALink href={ { query: {} } } scroll={ false }>shop</ALink></li>
                            {
                                data && data.products.categoryFamily.map( ( item, index ) => (
                                    <li className="breadcrumb-item" key={ `category-family-${index}` }><ALink href={ { query: { category: item.slug } } } scroll={ false }>{ item.name }</ALink></li>
                                ) )
                            }
                            <li className="breadcrumb-item active">
                                {
                                    query.search ?
                                        <>
                                            Search - <ALink href={ { query: { category: query.category } } } scroll={ false }>{ query.category }</ALink> / { query.search }
                                        </>
                                        : query.category
                                }
                            </li>
                        </>
                        : query.search ?
                            <>
                                <li className="breadcrumb-item"><ALink href={ { query: {} } } scroll={ false }>Shop</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">{ `Search - ${query.search}` }</li>
                            </>
                            : query.tag ?
                                <>
                                    <li className="breadcrumb-item"><ALink href={ { query: {} } } scroll={ false }>Shop</ALink></li>
                                    <li className="breadcrumb-item active" aria-current="page">{ `Product Tag - ${query.tag}` }</li>
                                </>
                                : <li className="breadcrumb-item active" aria-current="page">Shop</li>
                }
            </ol>
        </div>
    </nav>

    <div className="container">
        <div className="row">
            <div className="col-lg-9 main-content">

                <ShopBanner />

                <nav className="toolbox sticky-header mobile-sticky">
                    <div className="toolbox-left">
                        <a href="#" className="sidebar-toggle" onClick={ e => sidebarToggle( e ) }>
                            <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg">
                                <line x1="15" x2="26" y1="9" y2="9" className="cls-1"></line>
                                <line x1="6" x2="9" y1="9" y2="9" className="cls-1"></line>
                                <line x1="23" x2="26" y1="16" y2="16" className="cls-1"></line>
                                <line x1="6" x2="17" y1="16" y2="16" className="cls-1"></line>
                                <line x1="17" x2="26" y1="23" y2="23" className="cls-1"></line>
                                <line x1="6" x2="11" y1="23" y2="23" className="cls-1"></line>
                                <path
                                    d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z"
                                    className="cls-2"></path>
                                <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2"></path>
                                <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3"></path>
                                <path
                                    d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z"
                                    className="cls-2"></path>
                            </svg>
                            <span>Filter</span>
                        </a>

                        {/* <div className="toolbox-item toolbox-sort">
                            <label>Sort By:</label>

                            <div className="select-custom">
                                <select name="orderby" className="form-control" value={ sortBy } onChange={ e => onSortByChange( e ) }>
                                    <option value="default">Default sorting</option>
                                    <option value="popularity">Sort by popularity</option>
                                    <option value="rating">Sort by average rating</option>
                                    <option value="date">Sort by newness</option>
                                    <option value="price">Sort by price: low to high</option>
                                    <option value="price-desc">Sort by price: high to low</option>
                                </select>
                            </div>
                        </div> */}
                    </div>

                    <div className="toolbox-right">
                        {/* <div className="toolbox-item toolbox-show">
                            <label>Show:</label>

                            <div className="select-custom">
                                <select name="count" className="form-control" value={ perPage } onChange={ ( e ) => onPerPageChange( e ) }>
                                    <option value="10">10</option>
                                    <option value="15">20</option>
                                    <option value="20">30</option>
                                </select>
                            </div>
                        </div> */}

                        <div className="toolbox-item layout-modes">
                            <span style={{ marginRight: '10px' }}><ALink href={ { pathname: location.pathname } } className="layout-btn btn-grid active" title="Grid">
                                <i className="icon-mode-grid"></i>
                            </ALink>
                            </span>
                            {bannerguid!=null?<ALink href={`/shop/list?bannerGuid=${bannerguid}`} className="layout-btn btn-list" title="List">
                                <i className="icon-mode-list"></i>
                            </ALink>
                            :
                            <ALink href={`/shop/list`} className="layout-btn btn-list" title="List">
                                <i className="icon-mode-list"></i>
                            </ALink>}
                       
                        </div>
                    </div>
                </nav>

                <ProductsGrid products={ products } loading={ loading } perPage={ perPage } gridClass="col-6 col-sm-4 col-md-3 col-xl-5col" />

                {   ( products && products.length ) ?
                    <nav className="toolbox toolbox-pagination">
                        <div className="toolbox-item toolbox-show">
                            {/* <label>Show:</label>

                            <div className="select-custom">
                                <select name="count" className="form-control" value={ perPage } onChange={ e => onPerPageChange( e ) }>
                                 
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div> */}
                        </div>
                        <Pagination totalPages={ totalPage } OnChangePagerNumber={handlePageNumber}/>
                    </nav>
                    : ''
                }
            </div>

            <ShopSidebarOne onChangePrice={getProductPriceRange} onChangeCategory={getCategoryProducts}/>
        </div>

        <div className="mb-4"></div>
    </div>
</main>
)

};

export default Shop;
