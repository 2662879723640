import { useNavigate } from "react-router-dom";
import { useState ,useEffect} from "react";
import ALink from "../../components/common/ALink";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
export default function Signup() {
    const navigate=useNavigate();
    const [loading,setLoading]=useState(false);
    const inputStyle = {
        borderRadius: '7px', // Adjust the value for more or less curvature
        border: '2px solid #ccc', // Adjust the border color if needed
        padding: '10px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        borderRadius: '5px',
        padding: '10px 20px',
        marginBottom: '10px',
        width: '100%',
        boxSizing: 'border-box',
        backgroundColor: '#000',
        color: '#fff',
        border: 'none',
        cursor: loading ? 'not-allowed' : 'pointer',
        height:"59px",
        opacity: loading ? 1 : undefined, // Keep the opacity same when loading

    };

    const footerTextStyle = {
        marginTop: '10px',
        textAlign: 'center'
    };
    const errorStyle={
        color: 'red',
        marginBottom: '10px',
        textAlign:"center"
    }


    const [errorMessage, setErrorMessage] = useState("");

    const [UserInfo,setUserInfo]=useState({
        "First-Name":"",
        "Last-Name":"",
        "login-email":"",
        "login-password":"",
        "confirm-password":"",
    })

   

    const handleInputdata=(e)=>{
        setLoading(false);
        const {id,value}=e.target;
        setUserInfo((prevalue)=>({
            ...prevalue,
            [id]:value
        }));
    }

    const OnSubmitUser=(e)=>{
        setLoading(true);
        e.preventDefault();

        if(UserInfo["login-password"]!==UserInfo["confirm-password"]){
           setErrorMessage("Passwords do not match!");
           setLoading(false);
        }else{
            setErrorMessage("");
            console.log('UserInfo',UserInfo);
           setLoading(false);
            navigate("/pages/otp",{state:UserInfo})
        }
    }
    console.log('PassWordMismatch',errorMessage);

    return (
        <main className="main">
              <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                                <li className="breadcrumb-item"><ALink href="/shop">Shop</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    My Account
								</li>
                            </ol>
                        </div>
                    </nav>

                    <h1>My Account</h1>
                </div>
            </div>
            <div className="container d-flex flex-column align-items-center">
                <div className="container login-container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="login-box">
                                <div className="row">
                                    <div className="col-md-6 m-auto">
                                        <div className="heading mb-1">
                                            <h2 className="title text-center">Sign Up</h2>
                                        </div>

                                        <form onSubmit={OnSubmitUser}>
                                        {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
                                            <table style={{width: '100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <label htmlFor="First-Name">
                                                                First Name <span className="required">*</span>
                                                            </label>
                                                            <input 
                                                                type="text" 
                                                                className="form-input form-wide" 
                                                                id="First-Name" 
                                                                style={inputStyle}
                                                                required 
                                                                onChange={handleInputdata}
                                                            />
                                                        </td>
                                                        <td>
                                                            <label htmlFor="Last-Name">
                                                                Last Name <span className="required">*</span>
                                                            </label>
                                                            <input 
                                                                type="text" 
                                                                className="form-input form-wide" 
                                                                id="Last-Name" 
                                                                style={inputStyle}
                                                                required 
                                                                onChange={handleInputdata}
                                                            />  
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <label htmlFor="login-email">
                                                Email address <span className="required">*</span>
                                            </label>
                                            <input 
                                                type="email" 
                                                className="form-input form-wide" 
                                                id="login-email" 
                                                style={inputStyle}
                                                required 
                                                onChange={handleInputdata}
                                            />

                                            <label htmlFor="login-password">
                                                Password <span className="required">*</span>
                                            </label>
                                            <input 
                                                type="password" 
                                                className="form-input form-wide" 
                                                id="login-password" 
                                                style={inputStyle}
                                                required 
                                                onChange={handleInputdata}
                                            />
                                            <label htmlFor="confirm-password">
                                                Confirm Password <span className="required">*</span>
                                            </label>
                                            <input 
                                                type="password" 
                                                className="form-input form-wide" 
                                                id="confirm-password" 
                                                style={inputStyle}
                                                required 
                                                onChange={handleInputdata}
                                            />
                                            <div className="form-footer">
                                                <div className="custom-control custom-checkbox mb-0">
                                                    <input 
                                                        type="checkbox" 
                                                        className="custom-control-input" 
                                                        id="remember-me" 
                                                    />
                                                    <label className="custom-control-label mb-0" htmlFor="remember-me">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-dark btn-md w-100" style={buttonStyle} disabled={loading}>{
                                            loading?  <Box sx={{ display: 'flex',alignItems: 'center', justifyContent: 'center', }}>
                                            <CircularProgress sx={{color:"white"}}/>
                                          </Box>:"Sign Up"
                                            }</button>
                                            <div style={footerTextStyle}>Already Have Account? <ALink href={"/pages/login"}> Login </ALink></div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
