import React from 'react';
import { useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { baseUrl, uiUrl } from '../../config/config';

// import logo from './images/logo.png'; // Adjust the path to the logo image

const styles = {
    wrapper: {
        padding: '0 0 100px',
        backgroundImage: 'url("images/bg.png")',
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundAttachment: 'fixed',
        minHeight: '100%'
    },
    logo: {
        maxWidth: '40%',
    },
    input: {
        backgroundColor: '#f5f8fa',
        borderColor: '#f5f8fa',
        color: '#5e6278',
        borderRadius: '8px',
    },
    otpInput: {
        width: '19%',
        height: '70px',
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '600',
        borderRadius: '10px',
    },
    submitBtn: {
        padding: '10px 15px',
        fontWeight: '500',
    }
};

const OTPVerificationForm = () => {
    const location=useLocation();
    const {state}=location;
    console.log("location",location);
    console.log("state",state);
    const[otp,setOtp]=useState(Array(6).fill(''));
    const [userInfo ,setUserInfo]=useState([]);
    useEffect(()=>{
        setUserInfo(state);
    },[])

    const handleChange=(element,index)=>{
        console.log("element",element.value)
        console.log("index",index)
    if (isNaN(element.value)) return
    const value=[...otp];
    value[index]=element.value;
    setOtp(value);
    
    //move to next input field
    if(element.nextSibling){
        element.nextSibling.focus();
    }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        //currently we are sending otp default 1234 once otp section is done we need to send getDynamic input otp
        const getOtpString=otp.join('');
        const getOtp=parseInt(otp.join(''));
        console.log('getOtp ',getOtp);
        console.log('getOtp ',typeof getOtp);
        const userinfoData={
            firstName:userInfo["First-Name"],
            lastName:userInfo["Last-Name"],
            email:userInfo["login-email"],
            password:userInfo["login-password"],
            confirmPassword:userInfo["confirm-password"],
            otp:1234
        }
        console.log("getOtp.length",getOtpString.length);
        if(getOtpString.length===4){
            axios.post(`${baseUrl}/v1/customer`, {
                customer: {
                    ...userinfoData
                }
            }, {
                headers: {
                    "service_ref": "8xuf4dev",
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                console.log(response.data);
                if(response.data.success==true){
                    window.location.href=`${uiUrl}`
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
            
        }else{
            console.log('Enter Correct Otp');
        }
    }

    return (
        <section style={styles.wrapper}>
            <div className="container">
                <div className="col-sm-8 offset-sm-2 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 text-center">
                    <div className="logo">
                        {/* <img decoding="async" src={logo} style={styles.logo} alt="logo" /> */}
                    </div>
                    <form className="rounded bg-white shadow p-5" onSubmit={handleSubmit}>
                        <h3 className="text-dark fw-bolder fs-4 mb-2">Otp Verification</h3>
                        <div className="fw-normal text-muted mb-4">
                            Enter the verification code we sent to
                        </div>  
                        <div className="d-flex align-items-center justify-content-center fw-bold mb-4">
                            {[...Array(6)].map((_, index) => (
                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-asterisk" viewBox="0 0 16 16">
                                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                                </svg>
                            ))}
                            <span>8459</span>
                        </div>
                        <div className="otp_input text-start mb-2">
                            <label htmlFor="digit">Type your 4 digit security code</label>
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                {[...Array(6)].map((_, index) => (
                                    <input key={index} type="text" className="form-control" style={styles.otpInput} placeholder="" maxLength={1}
                                    onChange={(e)=>{handleChange(e.target,index)}}
                                    />
                                ))}
                            </div> 
                        </div>  
                        <button type="submit" className="btn btn-primary my-4" style={styles.submitBtn}>Submit</button>
                        <div className="fw-normal text-muted mb-2">
                            Didn’t get the code ? <a href="#" className="text-primary fw-bold text-decoration-none">Resend</a>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default OTPVerificationForm;
