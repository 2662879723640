import { createSlice } from "@reduxjs/toolkit";

const cartDetailsSlice = createSlice({
  name:"cartDetails",
  initialState: {
    // Your initial state here
   cartData:''
  },

  reducers: {
    setCartDetails: (state, action) => {
      state.cartData = action.payload;
     
    },
  },
});

export const { setCartDetails } = cartDetailsSlice.actions;
export const getCartDetails = (state) => state.user;

export default cartDetailsSlice.reducer;
