import ALink from "../../components/common/ALink";
import { useState,useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { baseUrl } from "../../config/config";


export default function ForgotPassword() {

    const [loading,setLoading]=useState(false);


    const [forgotInfo,setForgotInfo]=useState({
        "reset-email":"",
    })

    const HandleInfo=(e)=>{
        const {id,value}=e.target;
        setForgotInfo((prevalue)=>({
             ...prevalue,
             [id]:value
         }))
     } 

     const handleSubmit=(e)=>{
         e.preventDefault();
         console.log('Login Info ',forgotInfo);
        setLoading(true);
         const obj={
             "email":forgotInfo["reset-email"],
         }
         console.log('Obj ',obj);
         axios.post(`${baseUrl}/v1/customer/resetPasswordToken`,{
             "customer":{
                 ...obj
             }
         },{
             "headers":{
                 "service_ref":"8xuf4dev",
                 "Content-Type":"application/json"
             }
         })
         .then((responce)=>{
             console.log('Responce --> ',responce);
             if(responce.data.success=true){
                  setLoading(false);
                //  window.location.href="http://localhost:3001/";
                    toast.success("A link to reset your password has been sent to your email.",{
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        style: {
                            whiteSpace: 'nowrap',
                            minWidth: '500px', // Adjust the minimum width as necessary,
                            textAlign:"center"
                        }
                    })
                
             }else {
                 console.log(responce);
             }
         })
         .catch((error)=>{
             setLoading(false);
             console.log('Error',error);
             console.log('Error',error?.response?.data?.error?.message);
             toast.error("Error: " + error?.response?.data?.error?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    whiteSpace: 'nowrap',
                    minWidth: '500px', // Adjust the minimum width as necessary,
                    textAlign:"center",
                    paddingLeft:"20px",
                    paddingRight:"20px"
                }
            });
            console.log('Error', error);
        });
 
     }

    return (
        <main className="main">
            <ToastContainer
                 position="top-center" // Set the position to top-center
                 autoClose={5000}
                 hideProgressBar
                 newestOnTop
                 closeOnClick
                 rtl={false}
                 pauseOnFocusLoss
                 draggable
                 pauseOnHover/>
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                                <li className="breadcrumb-item"><ALink href="/shop">Shop</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    My Account
								</li>
                            </ol>
                        </div>
                    </nav>

                    <h1>My Account</h1>
                </div>
            </div>

            <div className="container reset-password-container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="feature-box border-top-primary">
                            <div className="feature-box-content">
                                <form className="mb-0" onSubmit={handleSubmit}>
                                    <p>
                                        Lost your password? Please enter your
                                         email address. You will receive
                                        a link to create a new password via email.
									</p>
                                    <div className="form-group mb-0">
                                        <label htmlFor="reset-email" className="font-weight-normal"> Email</label>
                                        <input type="email" className="form-control" id="reset-email" name="reset-email" onChange={HandleInfo}
                                            required />
                                    </div>

                                    <div className="form-footer mb-0">
                                        <ALink href="/pages/login">Click here to login</ALink>

                                        <button type="submit"
                                            className="btn btn-md btn-primary form-footer-right font-weight-normal text-transform-none mr-0">
                                            Reset Password
										</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && (
                <div className="loader-overlay">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                        <span style={{marginTop:"10px"}}>Loading</span>
                    </Box>
                </div>
            )}

            <style jsx>{`
                .loader-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
            `}</style>

        </main>
    )
}