import { useState, useEffect } from 'react';
// import { useRouter } from 'next/router';
import axios from 'axios';
// import { useLazyQuery } from '@apollo/react-hooks';

import ALink from '../../components/common/ALink';
import ShopBanner from '../../components/partials/shop/shop-banner';
import ShopSidebarOne from '../../components/partials/shop/sidebar/shop-sidebar-one';
import Pagination from '../../components/features/pagination';
import ProductsRow from '../../components/partials/products-collection/product-row';
import { baseUrl } from '../../config/config';

// import withApollo from '../../server/apollo';
// import { GET_PRODUCTS } from '../../server/queries';

const ProductList =() =>{

    const [products , setProducts] = useState([]);
    const [ perPage, setPerPage ] = useState( 12 );
    const [ sortBy, setSortBy ] = useState('default');
    const [categoryGuids, setCategoryGuids] = useState([]);
    const [subCategoryGuids, setSubCategoryGuids] = useState([]);
    const [selectedPriceRange, setSelectedPriceRange] = useState({min:"", max:""});
    const [productsState, setProductsState] = useState({ data: null, loading: false, error: null });
    const [categoryObj , setCategoryObj] = useState();
    const { data, loading, error } = productsState;
        // const query = new URLSearchParams(location.search);
    const guid=window.sessionStorage.getItem("Storeguid");

    const params=new URLSearchParams(window.location.search);
    const bannerguid=params.get("bannerGuid");
    console.log('params ',params);
    console.log('bannerguid ',bannerguid);

    useEffect(()=>{
        window.scroll(0,0)
    },[])

useEffect(() => {
        if(bannerguid){
        axios.get(`${baseUrl}/v1/product/${bannerguid}/banner/products?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}` , { headers: { 'service_ref': '8xuf4dev'}})
        .then((response)=>{
            console.log('Responce From related product ',response);
            const responseData = response.data;
            console.log('Responce Data ',responseData);
            if(sortBy === "price"){
                const priceLowToHigh = responseData.data.products.sort((a,b)=>{
                    return a.price - b.price;
                })
                console.log("price low to high", priceLowToHigh);
                setProducts(priceLowToHigh)
            }else if(sortBy === "price-desc"){
                const priceHighToLow = responseData.data.products.sort((a,b)=>{
                    return b.price - a.price;
                })
                console.log("priceHigh to low", priceHighToLow);
                setProducts(priceHighToLow)
            }else if(sortBy==='default'){
                setProducts(responseData.data.products);
            }
        }) 
        }else{
            axios.get(`${baseUrl}/v1/product/store/${guid}?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}` , { headers: { 'service_ref': '8xuf4dev'}})
                .then(response => {
                    // Access the response data
                    const responseData = response.data;
                    console.log('Responce Data ',responseData);
                    if(sortBy === "price"){
                        const priceLowToHigh = responseData.data.products.sort((a,b)=>{
                            return a.price - b.price;
                        })
                        console.log("price low to high", priceLowToHigh);
                        setProducts(priceLowToHigh)
                    }else if(sortBy === "price-desc"){
                        const priceHighToLow = responseData.data.products.sort((a,b)=>{
                            return b.price - a.price;
                        })
                        console.log("priceHigh to low", priceHighToLow);
                        setProducts(priceHighToLow)
                    }else if(sortBy==='default'){
                        setProducts(responseData.data.products);
                    }
                })
                .catch(error => {
                    // Handle any errors
                });
            }
            
    }, [selectedPriceRange , sortBy])
    
    
    useEffect(() => {
        if(bannerguid){ 
            axios.get(`${baseUrl}/v1/product/${bannerguid}/banner/products?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}` , { headers: { 'service_ref': '8xuf4dev'}})
            .then((response)=>{
                const responseData = response.data;
                console.log('responseData',responseData)
                setProducts(responseData.data.products)
            });
        }else{
            //same goes for this also do the same 
            const categoryGuidsParam = JSON.stringify(categoryGuids); // Convert to JSON string
            const subCategoryGuidsParam = JSON.stringify(subCategoryGuids); // Convert to JSON string
            axios.get(`${baseUrl}/v1/product/store/${guid}?minPrice=${selectedPriceRange.min}&maxPrice=${selectedPriceRange.max}&categoryGuids=${encodeURIComponent(categoryGuidsParam)}&subcategoryGuids=${encodeURIComponent(subCategoryGuidsParam)}` , { headers: { 'service_ref': '8xuf4dev'}})
                .then(response => {
                    // Access the response data
                    const responseData = response.data;
                    setProducts(responseData.data.products)
                    // Process the response data here
                })  
                .catch(error => {
                    // Handle any errors
                });
            }
        }, [selectedPriceRange ,categoryGuids , subCategoryGuids])
    
    // const router = useRouter();
    // const query = router.query;
    // const [ getProducts, { data, loading, error } ] = useLazyQuery(  );

    // const products = data && data.products.data;
    // const totalPage = data ? parseInt( data.products.total / perPage ) + ( data.products.total % perPage ? 1 : 0 ) : 1;

    // useEffect( () => {
    //     let offset = document.querySelector( '.main-content' ).getBoundingClientRect().top + window.pageYOffset - 58;
    //     setTimeout( () => {
    //         window.scrollTo( { top: offset, behavior: 'smooth' } );
    //     }, 200 );

    //     let page = query.page ? query.page : 1;

    //     getProducts( {
    //         variables: {
    //             list: true,
    //             search: query.search,
    //             colors: query.colors ? query.colors.split( ',' ) : [],
    //             sizes: query.sizes ? query.sizes.split( ',' ) : [],
    //             min_price: parseInt( query.min_price ),
    //             max_price: parseInt( query.max_price ),
    //             category: query.category,
    //             tag: query.tag,
    //             sortBy: sortBy,
    //             from: perPage * ( page - 1 ),
    //             to: perPage * page
    //         }
    //     } );
    // }, [ query, perPage, sortBy ] )

    function onPerPageChange ( e ) {
        console.log('E in On per Page ',e);
        setPerPage( e.target.value );
        // router.push( {
        //     pathname: router.pathname,
        //     query: {
        //         ...query,
        //         page: 1
        //     }
        // } );
    }

    function onSortByChange ( e ) {
        // router.push( {
        //     pathname: router.pathname,
        //     query: {
        //         ...query,
        //         sortBy: e.target.value,
        //         page: 1
        //     }
        // } )
        setSortBy( e.target.value );
    }

    function sidebarToggle ( e ) {
        let body = document.querySelector( 'body' );
        e.preventDefault();
        if ( body.classList.contains( 'sidebar-opened' ) ) {
            body.classList.remove( 'sidebar-opened' );
        } else {
            body.classList.add( 'sidebar-opened' );
        }
    }
    function getProductPriceRange(data){

        setSelectedPriceRange(data );
    }
    
    function getCategoryProducts(category){
        console.log("category that are selected", category);
        setCategoryObj(category)
        if (category.catGuid) {
            // It's a main category
            if (!categoryGuids.includes(category.catGuid)) {
                setCategoryGuids([ category.catGuid]);
            }
            setSubCategoryGuids([]); // Reset subcategory because we're at top level
        } else if (category.subCategory && category.subCategory.parentCatGuid) {
            // It's a subcategory
            if (!categoryGuids.includes(category.subCategory.parentCatGuid)) {
                setCategoryGuids([category.subCategory.parentCatGuid]);
            }
            if (!subCategoryGuids.includes(category.subCategory.subCatGuid)) {
                setSubCategoryGuids([category.subCategory.subCatGuid]);
            }
        }
    }

    // if ( error ) {
    //     return <div>{ error.message }</div>
    // }

    return (
        <main className="main">
            <ShopBanner />

            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                        {/* { */}
                                <>
                                    <li className="breadcrumb-item"><ALink href="/shop" scroll={ false }>shop</ALink></li>
                                    {/* {
                                        data && data.products.categoryFamily.map( ( item, index ) => (
                                            <li className="breadcrumb-item" key={ `category-family-${index}` }><ALink href={ { query: { category: item.slug } } } scroll={ false }>{ item.name }</ALink></li>
                                        ) )
                                    } */}
                                    <li className="breadcrumb-item active">
                                        {/* {
                                            query.search ?
                                                <>
                                                    Search - <ALink href={ { query: { category: query.category } } } scroll={ false }>{ query.category }</ALink> / { query.search }
                                                    Search - <ALink href={ { query: { category: query.category } } } scroll={ false }>{ query.category }</ALink> / { query.search }
                                                </>
                                                : query.category
                                        } */}
                                    </li>
                                </>
                                {/* // : query.search ?
                                //     <>
                                //         <li className="breadcrumb-item"><ALink href={ { pathname: router.pathname, query: {} } } scroll={ false }>shop</ALink></li>
                                //         <li className="breadcrumb-item active" aria-current="page">{ `Search - ${query.search}` }</li>
                                //     </>
                                //     : query.tag ?
                                //         <>
                                //             <li className="breadcrumb-item"><ALink href={ { pathname: router.pathname, query: {} } } scroll={ false }>shop</ALink></li>
                                //             <li className="breadcrumb-item active" aria-current="page">{ `Product Tag - ${query.tag}` }</li>
                                //         </>
                                //         : <li className="breadcrumb-item active" aria-current="page">Shop</li>
                        } */}
                    </ol>
                </div>
            </nav>

            <div className="container">
                <div className="row main-content">
                    <div className="col-lg-9">
                        <nav className="toolbox sticky-header mobile-sticky">
                            <div className="toolbox-left">
                                <a href="#" className="sidebar-toggle" onClick={ e => sidebarToggle( e ) }>
                                    <svg data-name="Layer 3" id="Layer_3" viewBox="0 0 32 32"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <line x1="15" x2="26" y1="9" y2="9" className="cls-1"></line>
                                        <line x1="6" x2="9" y1="9" y2="9" className="cls-1"></line>
                                        <line x1="23" x2="26" y1="16" y2="16" className="cls-1"></line>
                                        <line x1="6" x2="17" y1="16" y2="16" className="cls-1"></line>
                                        <line x1="17" x2="26" y1="23" y2="23" className="cls-1"></line>
                                        <line x1="6" x2="11" y1="23" y2="23" className="cls-1"></line>
                                        <path
                                            d="M14.5,8.92A2.6,2.6,0,0,1,12,11.5,2.6,2.6,0,0,1,9.5,8.92a2.5,2.5,0,0,1,5,0Z"
                                            className="cls-2"></path>
                                        <path d="M22.5,15.92a2.5,2.5,0,1,1-5,0,2.5,2.5,0,0,1,5,0Z" className="cls-2"></path>
                                        <path d="M21,16a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" className="cls-3"></path>
                                        <path
                                            d="M16.5,22.92A2.6,2.6,0,0,1,14,25.5a2.6,2.6,0,0,1-2.5-2.58,2.5,2.5,0,0,1,5,0Z"
                                            className="cls-2"></path>
                                    </svg>
                                    <span>Filter</span>
                                </a>

                                <div className="toolbox-item toolbox-sort">
                                    <label>Sort By:</label>

                                    <div className="select-custom">
                                        <select name="orderby" className="form-control" value={ sortBy } onChange={ e => onSortByChange( e ) }>
                                            <option value="default">Default sorting</option>
                                            {/* <option value="popularity">Sort by popularity</option>
                                            <option value="rating">Sort by average rating</option>
                                            <option value="date">Sort by newness</option> */}
                                            <option value="price">Sort by price: low to high</option>
                                            <option value="price-desc">Sort by price: high to low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="toolbox-right">
                                <div className="toolbox-item toolbox-show">
                                    <label>Show:</label>

                                    <div className="select-custom">
                                        <select name="count" className="form-control" value={ perPage } onChange={ ( e ) => onPerPageChange( e ) }>
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="36">36</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="toolbox-item layout-modes">
                                    <span style={{marginRight:'10px'}}>
                                    <ALink href={ { pathname: '/shop' } } className="layout-btn btn-grid" title="Grid">
                                        <i className="icon-mode-grid"></i>
                                    </ALink>
                                    </span>
                                    <ALink href={ { pathname: '/shop/list'} } className="layout-btn btn-list active" title="List">
                                        <i className="icon-mode-list"></i>
                                    </ALink>
                                </div>
                            </div>
                        </nav>

                        {/* <ProductsRow products={ products } loading={ loading } perPage={ perPage } /> */}
                        <ProductsRow products={ products } perPage={ perPage } />

                        {  ( products && products.length ) ?
                        // { loading || ( products && products.length ) ?
                            <nav className="toolbox toolbox-pagination">
                                <div className="toolbox-item toolbox-show">
                                    <label>Show:</label>

                                    <div className="select-custom">
                                        {/* <select name="count" className="form-control" value={ perPage }> */}
                                        <select name="count" className="form-control" value={ perPage } onChange={ e => onPerPageChange( e ) }>
                                            <option value="12">12</option>
                                            <option value="24">24</option>
                                            <option value="36">36</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <Pagination totalPage={ totalPage } /> */}
                            </nav>
                            : ''
                        }
                    </div>

                    <ShopSidebarOne onChangePrice={getProductPriceRange} onChangeCategory={getCategoryProducts}/>
                </div>
            </div>
        </main>
    )
}

export default ProductList ;