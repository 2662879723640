import { useState } from "react"
import ALink from "../components/common/ALink"
function Thankyou(){
const [flag ,setflag]=useState([]);

return(
    <div className="main">
    <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            {/* <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Wishlist
                                </li>
                            </ol> */}
                        </div>
                    </nav>

                    <h1>Thankyou...</h1>
                </div>
            </div>
            <div className="container">
                <div className="success-alert">
                    {
                        flag === 1 ? <p>Product successfully removed.</p> : ''
                    }
                    {
                        flag === 2 ? <p>Product added to cart successfully.</p> : ''
                    }
                </div>
                <div className="wishlist-title" style={{textAlign:'center'}}>
                    <h2>  Thank You for Your Order!</h2>


                    <p>
                        Your order has been successfully placed. We're excited to get your purchase on its way to you.

                        If you have any questions or need to make changes, feel free to reach out. Keep an eye on your inbox for updates and tracking information.

                        Thanks again for choosing us!</p>
                </div>

                <div className="wishlist-table-container">
                    <div className="table table-wishlist mb-0">
                        <div className="wishlist-empty-page text-center">
                            <i className="far fa-heart"></i>
                            <p>Thanks for visiting Store.Do</p>
                            <ALink href="/" className="btn btn-dark btn-add-cart product-type-simple btn-shop font1 w-auto" style={{color:"black"}}>
                                continue shopping </ALink>
                        </div>
                    </div>
                </div>



            </div>
    </div>
)
}

export default Thankyou