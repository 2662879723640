import React, { useState } from 'react';

const styles = {
  notFound: {
    textAlign: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
    height: '100vh', // Set the container height to full viewport height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '72px',
    fontWeight: 'bold',
    color: '#333',
    background: 'linear-gradient(90deg, #002f4b, #007bff)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    margin: 0,
  },
  message: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  description: {
    fontSize: '16px',
    color: '#666',
    margin: '20px 0',
  },
  homeButton: {
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    textDecoration: 'none',
    borderRadius: '5px',
    transition: 'background-color 0.3s',
  },
  homeButtonHover: {
    backgroundColor: '#0056b3',
  },
};

const NotFound = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={styles.notFound}>
      <h1 style={styles.title}>Oops!</h1>
      <p style={styles.message}>404 - STORE NOT FOUND</p>
      <p style={styles.description}>
        The store you are looking for might have been removed had its name changed or is temporarily unavailable.
      </p>
    </div>
  );
};

export default NotFound;