import { connect } from 'react-redux';
import { useState,useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import ALink from "../../components/common/ALink";
import { getCartDetails,setCartDetails } from '../../store/cart/cartDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import AddToCartPopup from '../../components/features/modals/add-to-cart-popup';
import {  ToastContainer , toast} from 'react-toastify';
import { actions as WishlistAction } from "../../store/wishlist";
import { actions as CartAction } from "../../store/cart";
import { actions as ModalAction } from "../../store/modal";
import { baseUrl } from '../../config/config';

function Wishlist(props) {
    const dispatch=useDispatch();
    const { wishlist, addToCart, removeFromWishlist, showQuickView } = props;
    const [flag, setFlag] = useState(0);
    
    const [updatedCartItems , setUpdatedCartItems]= useState([]);
    const cartData = useSelector(getCartDetails);//featching data 

    const [userWishlist ,setUserWishlist]=useState([]);
    const UserToken=window.sessionStorage.getItem("AccountToken");
    console.log('UserToken ',UserToken);

    useEffect(() => {
        setUpdatedCartItems(cartData?.cartData?.data);
        console.log('In WishList in useeffect ',updatedCartItems);
    }, [cartData])


    useEffect(()=>{
        axios.get(`${baseUrl}/v1/wishlist/customer`,{
            headers:{
                'service_ref':"8xuf4dev",
                'Authorization' :`Bearer ${UserToken}`
            }
        })
        .then((responce)=>{
            const userWishlist=responce?.data?.data?.wishlist;
            console.log('Responce --->',userWishlist);
            setUserWishlist(userWishlist)
        })
        .catch((error)=>{
            console.log('Error',error);
        })
    },[])

    function showToastWithImage(product) {
        console.log('product in show toast ',product);
        const toastContent = () => (
          <div>
            <AddToCartPopup product={product}/>   
          </div>
        );
      
        toast(toastContent);
      }


    // function onAddCartClick(productDetails, quantity) {
    //     // Create the product variant array 
    //     // const product_varient = {
    //     //         option_value: selectedOptions.name,
    //     //         option_price: selectedOptions.price,
    //     //         variant_sf_id: selectedOptions.variant_sf_id,
    //     // };
    
    //     // Create the product object including quantity and variants
    
    //        //we are going to add price from useState which we are using dynamically
    //     // const product = { ...productDetails, qty: quantity, variants: product_varient };
    //     const product = { ...productDetails, qty: quantity};


    //     showToastWithImage(product);
    
    //     // Initialize updatedCartItemsCopy
    //     let updatedCartItemsCopy;
    
    //     if (!updatedCartItems || updatedCartItems.length === 0) {
    //         updatedCartItemsCopy = [product];
    //     } else {
    //         let productExists = false;
    
    //         updatedCartItemsCopy = updatedCartItems.map((cartItem) => {
    //             if (cartItem.sf_id === product.sf_id) {
    //                 let variantMatch = true;
    
    //                 cartItem.variants.forEach((variant, index) => {
    //                     if (variant.variant_sf_id !== product_varient[index].variant_sf_id) {
    //                         variantMatch = false;
    //                     }
    //                 });
    
    //                 if (variantMatch) {
    //                     productExists = true;
    //                     return { ...cartItem, qty: cartItem.qty + quantity }; // Update quantity
    //                 }
    //             }
    //             return cartItem;
    //         });
    
    //         // If the product is not found or variant does not match, add it to the array
    //         if (!productExists) {
    //             updatedCartItemsCopy.push(product);
    //         }
    //     }
    
    //     console.log('updatedCartItemsCopy',updatedCartItemsCopy)
    //     // Dispatch the updated cart details
    //     dispatch(setCartDetails({ data: updatedCartItemsCopy }));
    // }

    const onMoveFromToWishlit = (e, item) => {
        setFlag(2);
        e.preventDefault();
        addToCart(item);
        removeFromWishlist(item);
    }

    const removeProduct = (e, item) => {
        setFlag(1);
        e.preventDefault();
        removeFromWishlist(item);
    }

    const onQuickViewClick = (e, product) => {
        e.preventDefault();
        showQuickView(product.slug);
    }

    return (
        <main className="main">
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">Home</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Wishlist
                                </li>
                            </ol>
                        </div>
                    </nav>

                    <h1>Wishlist</h1>
                </div>
            </div>

            <div className="container">
                <div className="success-alert">
                    {
                        flag === 1 ? <p>Product successfully removed.</p> : ''
                    }
                    {
                        flag === 2 ? <p>Product added to cart successfully.</p> : ''
                    }
                </div>
                {/* <div className="wishlist-title">
                    <h2>My wishlist on Porto Shop 3</h2>
                </div> */}
                {
                    userWishlist?.length === 0 ?
                        <div className="wishlist-table-container">
                            <div className="table table-wishlist mb-0">
                                <div className="wishlist-empty-page text-center">
                                    <i className="far fa-heart"></i>
                                    <p>No products added to the wishlist</p>
                                    <ALink href="/shop" className="btn btn-dark btn-add-cart product-type-simple btn-shop font1 w-auto">
                                        go shop </ALink>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="wishlist-table-container">
                            <table className="table table-wishlist mb-0">
                                <thead>
                                    <tr>
                                        <th className="thumbnail-col"></th>
                                        <th className="product-col">Product</th>
                                        <th className="price-col">Price</th>
                                        <th className="status-col">Stock Status</th>
                                        <th className="action-col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userWishlist?.map((item, index) => (
                                            <tr key={"wishlist-item" + index} className="product-row">
                                                <td className="media-with-lazy">
                                                    <figure className="product-image-container">
                                                        <ALink href={`/product/default/${item.productsDetails.name}`} className="product-image">
                                                            <LazyLoadImage
                                                                alt="product"
                                                                src={item.productsDetails?.imageUrls[0]}
                                                                threshold={500}
                                                                width="80"
                                                                height="80"
                                                            />
                                                        </ALink>
                                                        <a href="#" className="btn-remove icon-cancel" title="Remove Product" onClick={(e) => removeProduct(e, item)}></a>
                                                    </figure>
                                                </td>
                                                <td>
                                                    <h5 className="product-title">
                                                        <ALink href={`/product/default/${item.slug}`}>{item.productsDetails.name}</ALink>
                                                    </h5>
                                                </td>
                                                <td>
                                                    <div className="price-box">
                                                        {
                                                             <span className="product-price">{'' + item?.productsDetails.price}</span>

                                                            // item.price[0] == item.price[1] ?
                                                            //     <span className="product-price">{'$' + item.price[0].toFixed(2)}</span>
                                                            //     : item.variants.length > 0 ?
                                                            //         <span className="product-price">{'$' + item.price[0].toFixed(2)} &ndash; {'$' + item.price[1].toFixed(2)}</span>
                                                            //         : <>
                                                            //             <span className="old-price">{'$' + item.price[1].toFixed(2)}</span>
                                                            //             <span className="new-price">{'$' + item.price[0].toFixed(2)}</span>
                                                                    // </>
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="stock-status">{item?.productsDetails?.isAvailable ? 'In stock' : 'Out of stock'}</span>
                                                </td>
                                                <td className="action"> 
                                                    <ALink
                                                    href={`/product_detail/${item?.productsDetails?.name}/${item?.productsDetails?.guid}`}
                                                    state={item.productsDetails}
                                                    onClick={() => {
                                                    //   onClickProduct();
                                                    }}
                                                  
                                                    className="btn btn-quickview mt-1 mt-md-0"
                                                    title="Quick View"
                                                   >
                                                     Product Details
                                                   </ALink>
                                                    {
                                                        item?.variants?.length > 0 ?
                                                            <ALink className="btn btn-dark btn-add-cart product-type-simple btn-shop" href={`/product/default/${item.slug}`}>select options</ALink>
                                                            : <button className="btn btn-dark btn-add-cart product-type-simple btn-shop" onClick={(e) => { onMoveFromToWishlit(e, item) }}>
                                                                ADD TO CART
                                                            </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                }
            </div>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {
        wishlist: state.wishlist.list ? state.wishlist.list : []
    }
}

export default Wishlist;