import ALink from '../../common/ALink';
import banner1 from "../../../assets/images/home/banners/banner-1.jpg";
import { useEffect, useState } from 'react';

export default function ShopBanner(props) {
    const { nogap } = props;
    const [productBanner ,setProductBanner]=useState([]);

    useEffect(()=>{
        const BannerData=JSON.parse(window.localStorage.getItem("ShopBanner"));
        const FilterProductBanner=BannerData?.filter((value)=>value?.bannerPosition?.includes("Product Top"));
        console.log('Banner Data',FilterProductBanner);
        setProductBanner(FilterProductBanner[0]);
    },[])
    console.log('Banner In UseState',productBanner);

    return (
        <div className={`category-banner pt-0 pb-2 ${nogap ? "mb-0" : ''} `}>
               <ALink href={`/shop/?bannerGuid=${productBanner?.guid}`} className="btn ls-0 w-100">
            <img className="slide-bg" src={productBanner?.url} alt="banner" style={{
        height: "280px",
        width: "100%",
            objectFit: "inherit"
}}
               />
                </ALink>
        </div>
    )
}