// import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch , useSelector} from 'react-redux';
import { setCartDetails,getCartDetails } from '../../../store/cart/cartDetailsSlice';
import asusLaptop from "../../../assets/images/products/Laptops/asus.jpg";
import AddToCartPopup from "../../../components/features/modals/add-to-cart-popup";
import { ToastContainer, toast } from "react-toastify";
// Import Actions
// import { actions as WishlistAction } from "../../../store/wishlist";
// import { actions as CartAction } from "../../../store/cart";
// import { actions as ModalAction } from "../../../store/modal";



// Import Custom Component
import ALink from '../../common/ALink';
import ProductCountdown from '../product-countdown';

function ProductTwo ( props ) {
    const dispatch=useDispatch();
    const [UpdatedCartItem ,setUpdatedCartItem ]=useState([]);
    const cartItem =useSelector(getCartDetails);
    const [updatedCartItems, setUpdatedCartItems] = useState([]);
    const [price ,setPrice]=useState([]);
    //dipendency 
    useEffect(()=>{
        const cartData=cartItem?.cartData?.data; //array Of An Object
        setUpdatedCartItem(cartData)
    },[cartItem]);

    // const router = useRouter();
    const { adClass = "", link = "default", product } = props;

    useEffect(()=>{
        const priceCheck=product.options!=null|| undefined ?product.options[0].options[0].price:product.price;
        console.log('Price Check',priceCheck);  
        setPrice(priceCheck)
    },[])

    // console.log("product in Product Two ",product)

    function isSale () {
        return product.price[ 0 ] !== product.price[ 1 ] && product.variants.length === 0 ?
            '-' + ( 100 * ( product.price[ 1 ] - product.price[ 0 ] ) / product.price[ 1 ] ).toFixed( 0 ) + '%'
            :
            product?.variants?.find( variant => variant.sale_price ) ? "Sale" : false;
    }

    function isInWishlist () {
        // return product && props.wishlist.findIndex( item => item.slug === product.slug ) > -1;
    }

    function onWishlistClick ( e ) {
        e.preventDefault();
        if ( !isInWishlist() ) {
            let target = e.currentTarget;
            target.classList.add( "load-more-overlay" );
            target.classList.add( "loading" );

            setTimeout( () => {
                target.classList.remove( 'load-more-overlay' );
                target.classList.remove( 'loading' );
                props.addToWishList( product );
            }, 1000 );
        } else {
            // router.push( '/pages/wishlist' );
        }
    }

    // function onAddCartClick ( e ) {
    //     e.preventDefault();
    //     props.addToCart( product );
    // }

    //add to cart In Product List Done
    // function onAddCartClick(productDetails){
    //     let copyCartItem=[];
    //     const product={...productDetails , qty:1};
    //     console.log('Updated Cart Item ',UpdatedCartItem);
    //     if(UpdatedCartItem==undefined){ 
    //         copyCartItem.push(product);
    //     }else{
    //         copyCartItem=UpdatedCartItem.map((value)=>{
    //             if(value.sf_id==product.sf_id){
    //                 return {
    //                     ...value,
    //                     qty:value.qty+product.qty
    //                 }
    //             }else{
    //                 return value;
    //             }
    //         })
    //         const cartObj=copyCartItem.find((value)=>value.sf_id==product.sf_id);
    //         console.log('cartObj ',cartObj);
    //         if(cartObj===undefined){
    //             copyCartItem.push(product);
    //         }
    //     }
    //     dispatch(setCartDetails({data:copyCartItem}));

    //     console.log('Copy Cart Item In AddToCart Function ',copyCartItem);
    // }
    function showToastWithImage(product) {
        console.log("product in show toast ", product);
        const toastContent = () => (
          <div>
            <AddToCartPopup product={product} />
          </div>
        );
    
        toast(toastContent);
      }


    function onAddCartClick(productDetails) {
        console.log('Prodcut Detalis',productDetails);
              // Create the product variant array 
              console.log(typeof selectedOptions);
              const product_varient =productDetails.options!=null?{
                option_value: productDetails.options[0].options[0].name,
                option_price: productDetails.options[0].options[0].price,
                variant_sf_id: productDetails.options[0].options[0].variant_sf_id,
                
              }:{
                option_value: "",
                option_price: productDetails.price,
                variant_sf_id: productDetails.sf_id,
              };
              console.log('product_varient',product_varient);
              // Create the product object including quantity and variants
          
                 //we are going to add price from useState which we are using dynamically
              const product = { ...productDetails, qty: 1, variants: product_varient };
              showToastWithImage(product);
          
              // Initialize updatedCartItemsCopy
              let updatedCartItemsCopy;
          
              if (!updatedCartItems || updatedCartItems.length === 0) {
                  updatedCartItemsCopy = [product];
              } else {
                  let productExists = false;
          
                  updatedCartItemsCopy = updatedCartItems.map((cartItem) => {
                      if (cartItem.sf_id === product.sf_id) {
                          let variantMatch = true;
                              if (cartItem?.variants?.variant_sf_id !== product_varient?.variant_sf_id) {
                                  variantMatch = false;
                              }
                              console.log('variantMatch',variantMatch);
                          if (variantMatch) {
                              productExists = true;
                              return { ...cartItem, qty: cartItem.qty + product.qty }; // Update quantity
                          }
                      }
                      return cartItem;
                  });
          
                  // If the product is not found or variant does not match, add it to the array
                  if (!productExists) {
                      updatedCartItemsCopy.push(product);
                  }
              }
              console.log('UpdateCart Item copy ',updatedCartItemsCopy);
              // Dispatch the updated cart details
               dispatch(setCartDetails({ data: updatedCartItemsCopy }));
      }

    function onQuickViewClick ( e ) {
        e.preventDefault();
        props.showQuickView( product.slug );
    }

    return (
        <div className={ `product-default media-with-lazy left-details mb-2 product-list ${ adClass }` }>
            <figure>
                <ALink href={`/product_detail/${product.name}/${product.guid}`}state={product}>
                    <div className="lazy-overlay"></div>

                    <LazyLoadImage
                        alt="product"
                        // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROGswtbi-CqtO_5ecgfl_aVaCERXPu1ESJjA&s"
                        src={product.imageUrls!=null ? product.imageUrls[0]:asusLaptop}
                        threshold={ 500 }
                        effect="black and white"
                        width="100%"
                    />
                    {
                      product.imageUrls!==null?product.imageUrls.length>= 2 ?
                            <LazyLoadImage
                                alt="product"
                                src={product.imageUrls[1]}
                                threshold={ 500 }
                                effect="black and white"
                                wrapperClassName="product-image-hover"
                            />
                            : "" :asusLaptop
                    }
                </ALink>

                <div className="label-group">
                    { product.is_hot ? <div className="product-label label-hot">HOT</div> : '' }

                    { isSale() ? <div className="product-label label-sale">{ isSale() }</div> : '' }
                </div>

                {
                    product.until && product.until !== null &&
                    <ProductCountdown />
                }
            </figure>

            <div className="product-details">
                <div className="category-wrap">
                    <div className="category-list">
                        {
                            product.categories ?
                                product.categories.map( ( item, index ) => (
                                    <React.Fragment key={ item.slug + '-' + index }>
                                        <ALink href={ { pathname: '/shop', query: { category: item.slug } } }>
                                            { item.name }
                                        </ALink>
                                        { index < product.categories.length - 1 ? ', ' : "" }
                                    </React.Fragment>
                                ) ) : ""
                        }
                    </div>
                </div>

                <h3 className="product-title">
                    <ALink href={ `/product_detail/${product.name}/${product.guid}` }state={product}>{ product.name }</ALink>
                </h3>

                <div className="ratings-container">
                    <div className="product-ratings">
                        <span className="ratings" style={ { width: 20 * product.ratings + '%' } }></span>
                        {/* <span className="tooltiptext tooltip-top">{ product.ratings.toFixed( 2 ) }</span> */}
                    </div>
                </div>

                {/* <p className="product-description">
                    { product.description }
                </p> */}

                <div className="price-box">
                <span className="product-price">&#x20B9;{ price}</span>                    {/* {
                        product.price[ 0 ] == product.price[ 1 ] ?
                            <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) }</span>
                            : product.variants.length > 0 ?
                                <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) } &ndash; { '$' + product.price[ 1 ].toFixed( 2 ) }</span>
                                : <>
                                    <span className="old-price">{ '$' + product.price[ 1 ].toFixed( 2 ) }</span>
                                    <span className="product-price">{ '$' + product.price[ 0 ].toFixed( 2 ) }</span>
                                </>
                    } */}
                </div>

                <div className="product-action">
                    {
                        product?.variants?.length > 0 ?
                            <ALink href={ `/product/default/${ product.slug }` } className="btn-icon btn-add-cart"><i
                                className="fa fa-arrow-right"></i><span>SELECT OPTIONS</span></ALink>
                            : //<a href="#" className="btn-icon btn-add-cart product-type-simple" title="Add To Cart" onClick={()=>onAddCartClick(product)}><i
                            //     className="icon-shopping-cart"></i><span>ADD TO CART</span></a>
                            ""
                    }
                    {/* <a href="#" className={ `btn-icon-wish ${ isInWishlist() ? 'added-wishlist' : '' }` } onClick={ onWishlistClick } title={ `${ isInWishlist() === true ? 'Go to Wishlist' : 'Add to Wishlist' }` }><i className="icon-heart"></i></a>
                    <a href="#" className="btn-quickview" title="Quick View" onClick={ onQuickViewClick }><i
                        className="fas fa-external-link-alt"></i></a> */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ( state ) => {
    return {
        wishlist: state.wishlist.list ? state.wishlist.list : []
    }
}

export default ProductTwo ;