import Reveal from 'react-awesome-reveal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ALink from '../../common/ALink';
import { fadeInLeftShorter } from '../../../utils/data/keyframes'
import { useEffect,useState } from 'react';

import HomeBanner1 from "../../../assets/images/home/banners/home-banner1.jpg";
import HomeBanner2 from "../../../assets/images/home/banners/home-banner2.jpg";
import HomeBanner3 from "../../../assets/images/home/banners/home-banner3.jpg";
import HomeBanner4 from "../../../assets/images/home/banners/home-banner4.jpg";


export default function BannerSection() {
//fetch guid from LocalStorege call api and fetched the responce from that api which also and product with repective banner and show
const [middleBanner ,setmiddleBanner] =useState([]);

useEffect(()=>{
    const ShopBanner=JSON.parse(window.localStorage.getItem("ShopBanner"));
    console.log('Shop Banner In BannerSection ',ShopBanner);   
    const Middlebanner=ShopBanner.filter((value)=>value?.bannerPosition?.includes("Home Middle") );
    console.log('MiddleBanner ',Middlebanner);
    setmiddleBanner(Middlebanner);
},[]);

console.log('MiddleBanner ',middleBanner);

    return (
        <section className="bg-gray banners-section text-center mb-5">
            <div className="container py-2">
                <div className="row">
                {middleBanner && middleBanner.slice(0,4).map((value)=>(
                <div className="col-sm-6 col-lg-3">
                <Reveal keyframes={fadeInLeftShorter} delay={100} duration={1000} triggerOnce>
                    <div className="home-banner banner banner-sm-vw mb-0">
                    <ALink href={`/shop?bannerGuid=${value.guid}`} className="btn p-0" role="button">
                        <img src={value.url} width="419" style={{height:"300px"}}
                            alt="Banner" />
                            </ALink>
                        <div className="banner-layer banner-layer-bottom text-left">
                            {/* <h3 className="m-b-2">Sunglasses Sale</h3>
                            <h4 className="m-b-3">See all and find yours</h4> */}
                        </div>
                    </div>
                </Reveal>
            </div>
                ))
                }
                    {/* <div className="col-sm-6 col-lg-3">
                        <Reveal keyframes={fadeInLeftShorter} delay={100} duration={1000} triggerOnce>
                            <div className="home-banner banner banner-sm-vw mb-2">
                                <img src={HomeBanner1} width="419" height="629"
                                    alt="Banner" />
                                <div className="banner-layer banner-layer-bottom text-left">
                                    <h3 className="m-b-2">Sunglasses Sale</h3>
                                    <h4 className="m-b-3">See all and find yours</h4>
                                    <ALink href="/shop" className="btn  btn-dark text-white" role="button">Shop By Glasses</ALink>
                                </div>
                            </div>
                        </Reveal>
                    </div> */}
                    {/* <div className="col-sm-6 col-lg-3">
                        <Reveal keyframes={fadeInLeftShorter} delay={100} duration={1000} triggerOnce>
                            <div className="home-banner banner banner-sm-vw mb-2">
                                <img src={HomeBanner2} width="419" height="629" alt="Banner" />
                                <div className="banner-layer banner-layer-top ">
                                    <h3 className="mb-0">Cosmetics Trends</h3>
                                    <h4 className="m-b-4">Browse in all our categories</h4>
                                    <ALink href="/shop" className="btn  btn-dark text-white" role="button">Shop By Glasses</ALink>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <Reveal keyframes={fadeInLeftShorter} delay={100} duration={1000} triggerOnce>
                            <div className="home-banner banner banner-sm-vw mb-2">
                                <img src={HomeBanner3} width="419" height="629"
                                    alt="Banner" />
                                <div className="banner-layer banner-layer-middle">
                                    <h3 className="text-white m-b-1">Fashion Summer Sale</h3>
                                    <h4 className="text-white m-b-4">Browse in all our categories</h4>
                                    <ALink href="/shop" className="btn btn-light bg-white text-dark" role="button"style={{ color: '#fff' }}>Shop By
                                        Fashion</ALink>
                                </div>
                            </div>
                        </Reveal>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <Reveal keyframes={fadeInLeftShorter} delay={100} duration={1000} triggerOnce>
                            <div className="home-banner banner banner-sm-vw mb-2">
                                <img src={HomeBanner4}width="419" height="629"
                                    alt="Banner" />
                                <div className="banner-layer banner-layer-bottom banner-layer-boxed">
                                    <h3 className="m-b-2">UP TO 70% IN ALL BAGS</h3>
                                    <h4>Starting at $99</h4>
                                    <ALink href="/shop" className="btn  btn-dark text-white" role="button">Shop By Bags</ALink>
                                </div>
                            </div>
                        </Reveal>
                    </div> */}
                </div>
            </div>
        </section>
    )
}