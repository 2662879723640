import { configureStore } from "@reduxjs/toolkit";
import { persistStore ,persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cartReducer from "./cart/cartDetailsSlice";
// import assessmentReducer from './assessmentSlice'
// import userReducer from './userSlice'

const persistConfig={
  key:'root',
  storage,
}

const persistedReducer =persistReducer(persistConfig, cartReducer);

const store = configureStore({
  reducer: {
    // assessment: assessmentReducer,
    user: persistedReducer,
    // Add other reducers if needed
  },
});

const persistor=persistStore(store);

export {store, persistor};
