// routes.js

// import About from '../pages/About';
// import ContactUs from '../pages/ContactUs';
// import Home from '../pages/Home'
import About from '../pages/About_Us';
import Home from '../pages/Home';
import Shop from "../pages/shop";
import ProductList from "../pages/productList/productlist"
import ProductDetails from "../pages/ProductDetails/productDetails";
import Cart from '../pages/cart/Cart';
import PRoductDetails2 from "../pages/ProductDetails/ProductDetails2";
import CheckOut from "../pages/checkout/Checkout";
import Thankyou  from '../pages/thankyou';
import Account from '../pages/Account/account';
import Login from '../pages/Login/login';
import Signup from '../pages/Login/signUp';
import Otp from '../pages/Login/OtpPage';
import ForgotPassword from '../pages/Login/forgot_password';
import ChangePasswordForm from '../pages/Login/update_password';
import Wishlist from '../pages/Wishlist/wishlist';
import StoreNotFound from '../pages/StoreNotFound/Storenotfound';
// import Product from '../pages/Product';
// import Checkout from '../pages/checkout/Checkout';
// import ProductDetails from '../pages/productDetails/productDetails';
// import Thankyou from '../pages/thankyou';
// import ProductList from '../pages/productList/prouductList';
// import NewSale from '../components/sale/NewSale';

const routes = [
  { path: '/', exact :true ,component:<Home/>},
    {path: '/aboutus', component: <About/> },
    {path: '/shop', component:<Shop/>},
{path:'/shop/list', component:<ProductList/>},
//  {path:'/product_detail/:productName/:productId',component:<ProductDetails/>},
{path: '/pages/cart', component:<Cart/>},
 {path:"/product_detail/:productName/:productId",component:<PRoductDetails2/>},
 {path :"/pages/checkout",component:<CheckOut/>},
 {path:"/pages/thankyou",component:<Thankyou/>},
 {path:"/pages/account",component:<Account/>},
 {path:"/pages/login",component:<Login/>},
 {path:"/pages/signUp",component:<Signup/>},
 {path:"/pages/otp",component:<Otp/>},
 {path:"/pages/forgotpassword",component:<ForgotPassword/>},
 {path:"/update-password/:token",component:<ChangePasswordForm/>},
 {path:"/pages/wishlist",component:<Wishlist/>},
 {path:"/pages/Storenotfound",component:<StoreNotFound/>}
  // { path: '/product', component: <Product/> },
  // { path: '/about', component: <About/> },
  // {path: '/contact_us', component:<ContactUs/>},
  // {path: 'pages/checkout', component:<Checkout/>},
  // {path: '/product_detail/:productName/:productId', component:<ProductDetails/>},
  // {path: '/thankyou', component:<Thankyou/>},
  // 
  // {path: '/pages/product/list', component:<ProductList/>},
  // {path: '/pages/product/Sale', component:<NewSale/>},

  // { path: '/contact', component: <About/> }
];

export {routes};
