import SlideToggle from 'react-slide-toggle';
import ALink from "../../components/common/ALink";
import { getCartTotal } from '../../utils';
import { useDispatch,useSelector } from 'react-redux';
import {getCartDetails,setCartDetails} from "../../store/cart/cartDetailsSlice";
import { useEffect, useState } from 'react';
import validator from 'validator';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Payment from "../Payment";
import { event } from 'jquery';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { baseUrl, uiUrl } from '../../config/config';
import { Navigate, useNavigate } from 'react-router-dom';

function CheckOut(){
const dispatch=useDispatch();
const [loading,setLoading]=useState(false);
const [cartList ,setcartList]=useState([]);
const cartItem =useSelector(getCartDetails);
const [validationErrors, setValidationErrors] = useState({});
const [paymentMethod, setPaymentMethod] = useState('');
//this will send to payment component page
const [showPayment, setShowPayment] = useState(false);
const [orderAmount ,setOrderAmount ] = useState();
const [orderCurrency , setOrderCurrency]= useState();
const [orderId ,setOrderId] = useState();
const [orderGuid , setOrderGuid] = useState();
const [storeGuid , setStoreGuid] = useState();
const [subtotal , setSubTotal ] = useState();
const [formData ,setFormData]=useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: "",
    shipping_street: "",
    shipping_street2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_postalCode: "",
    shipping_country: "",
    billing_street: "",
    billing_street2: "",
    billing_city: "",
    billing_state: "",
    billing_postalCode: "",
    billing_country: "",
    paymentMethod: "",
    companyName:"",
})
const navigate=useNavigate();

const datacheckLocalStorege =localStorage.getItem("cartData");
console.log('cartItem',cartItem);

function FormDataFunction(e){
const {name ,value}=e.target;
console.log('e.target ',name ,value);
setFormData({...formData,[name]:value})
}

useEffect(()=>{
    let totalprice=0;
    const cart=cartItem?.cartData?.data;
    setcartList(cart);
    const storeguid=cart[0].store_guid;
    setStoreGuid(storeguid);
    if(cart && cart.length>0){
        cart.forEach(element => {
            //need to change based on price 
            totalprice = totalprice + (element?.variants?.option_price * element.qty);
        });
        setSubTotal(totalprice)
    }
},[])


const apiUrl = `${baseUrl}/v1/order`;


const handlePaymentChange = (event) => {
    console.log('event.target.value',event.target.value);
    setPaymentMethod(event.target.value);
   // console.log('Selected Payment Method:', event.target.value);
  };

function alterMsgFunction(Msg){
    toast.warning(Msg,{
        //   position: toast.POSITION.TOP_CENTER,
        autoClose: 5000, // Adjust the duration the toast stays visible (in milliseconds)
        hideProgressBar: false, // Set to true to hide the progress bar
        closeOnClick: true, // Set to false to disable close on click
        pauseOnHover: true, // Set to false to disable pause on hover
        draggable: true, // Set to false to disable dragging
        progress: undefined, // Set a custom progress indicator (e.g., progress: <Element>)
        className: 'custom-toast', // Add a custom CSS class for styling
        bodyClassName: 'custom-toast-body', // Add a custom CSS class for the toast body
        closeButton: false,
        style: { wordWrap: "break-word"  , padding:'20px' } // Set to true to show a close button
    })
}


function PlaceOrder(event){
console.log('event ',event)
    const errors={};
    console.log('Form Data ',formData);
    for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
            const value = formData[key];
         if (key === "firstName") {
            errors[key] = validator.isEmpty(value) ? `First Name is required` : "";
          } else if (key === "lastName") {
            errors[key] = validator.isEmpty(value)? `Last Name is required`: "";
          } else if (key === "email") {
            errors[key] = validator.isEmpty(value)? "Email is required": !validator.isLength(value, { min: 0, max: 1000 })
                ? `Character Length Exceeded` : "";
          } else if (key === "phoneNumber") {
            errors[key] = validator.isEmpty(value)
              ? "Phone Number is required"
              : !validator.isLength(value, { min: 0, max: 1000 })
                ? `Character Length Exceeded`
                : "";
          }
          else if (key === "shipping_street") {
              errors[key] = validator.isEmpty(value)
                ? "Address Line 1 is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "shipping_street2") {
              errors[key] = validator.isEmpty(value)
                ? "Address Line 2 is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "shipping_city") {
              errors[key] = validator.isEmpty(value)
                ? "City is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "shipping_state") {
              errors[key] = validator.isEmpty(value)
                ? "State is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "shipping_postalCode") {
              errors[key] = validator.isEmpty(value)
                ? "Postal Code is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "shipping_country") {
              errors[key] = validator.isEmpty(value)
                ? "Country is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }else if (key === "companyName") {
              errors[key] = validator.isEmpty(value)
                ? "Company Name is required"
                : !validator.isLength(value, { min: 0, max: 1000 })
                  ? `Character Length Exceeded`
                  : "";
            }
         
       
        }
      }
      console.log('Error',errors);  
      console.log('Form Data ',formData);
      setValidationErrors(errors);
    
      const hasErrors = Object.values(errors).some((error) => error !== "");
      console.log('hasError ',hasErrors);

      if(hasErrors || !paymentMethod){
      //toest Msg Check 
        if(hasErrors){
        alterMsgFunction("Please Fill The Details First");
    }else {
        alterMsgFunction("Please Select Payment Method");
    }
      }
      else{
        setLoading(true);
        //this will exicute when all the text field is fill 

    //  window.location.href="http://localhost:3001/pages/thankyou";

     const products_list =  cartList.map((pro)=>{  
        console.log('Product variants',pro.variants);   
        return {
            product_guid: pro.guid,
            quantity: pro.qty,
            price: pro?.variants?.option_price,
            variants : pro?.variants
           // variation:[ {RAM , guid} , {Storage , guid}]

        }
    })

    let orderData = {
        order: {
            store_guid : storeGuid,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            companyName: formData.companyName,
            phoneNumber: {
                countryCode: +1,
                number: formData.phoneNumber
            },
            products: products_list,
            // totalAmount: getCartTotal(cartList),
            totalAmount: subtotal,
            shippingAddress: {
                shipping_street: formData.shipping_street,
                shipping_street2: formData.shipping_street2,
                shipping_city: formData.shipping_city,
                shipping_state: formData.shipping_state,
                shipping_postalCode: formData.shipping_postalCode,
                shipping_country: formData.shipping_country
            },
            billingAddress: {
                billing_street: "",
                billing_street2: "",
                billing_city: "",
                billing_state: "",
                billing_postalCode: "",
                billing_country: ""
            },
            paymentMethod: paymentMethod,
        }
    }

     if(paymentMethod){
        console.log('Payment Method Is Selected');
        console.log('orderData',orderData);
     }else{
        console.log('Payment Method Is Not Selected ');
        console.log('orderData',orderData);
     }
    axios.post(apiUrl, orderData, { headers: { 'service_ref': '8xuf4dev' } })
    .then(response => {
        console.log('responce in post api',response);
        if(response?.data?.success === true){
        setLoading(false);
       // console.log("order with order id", response?.data?.data?.order_details?.guid);
        setOrderId(response?.data?.data?.order?.id)
        setOrderCurrency(response?.data?.data?.order?.currency)
        setOrderAmount(response?.data?.data?.order?.amount)
        setOrderGuid(response?.data?.data?.orderDetails?.guid);
        console.log('paymentMethod in post method ',paymentMethod);
        if(paymentMethod==='Cash On Delivery'){
            dispatch(setCartDetails([]))
            navigate(`/pages/thankyou`);
        }else if(paymentMethod==='Razorpay'){
         setShowPayment(true);
        }
        }
       
        // Handle response
    })
    .catch(error => {
        console.error('Error:', error);
        // Handle error
        setLoading(false);
    });
    }
    
    
}

const onClickPlaceOrder = () => {
    //  setShowPayment(true);
   // console.log("formData", formData);
   PlaceOrder()
    
}

console.log('validationErrors',validationErrors);

    return (
        <main className="main main-test">
            <div className="container checkout-container">
                <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
                    <li>
                        <ALink href="/pages/cart">Shopping Cart</ALink >
                    </li>
                    <li className="active">
                        <ALink href="/pages/checkout">Checkout</ALink >
                    </li>
                    <li className="disabled">
                        <ALink href="#">Order Complete</ALink >
                    </li>
                </ul>
                {
                     cartList==undefined|| cartList.length === 0 ?
                        <div className="cart-empty-page text-center">
                            <p className="noproduct-msg mb-2">Checkout is not available while your cart is empty.</p>
                            <i className="icon-bag-2"></i>
                            <p>No products added to the cart</p>
                            <ALink href="/shop" className="btn btn-dark btn-add-cart product-type-simple btn-shop font1" style={{color:"white"}}>
                                return to shop
                            </ALink>
                        </div>
                        :
                        <>
                            {/* <div className="checkout-discount">
                                <SlideToggle duration={ 300 } collapsed >
                                    { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                        <h4>Returning customer? <button className="btn btn-link btn-toggle" onClick={ onToggle }>Login</button>
                                            <div className="login-form-container" ref={ setCollapsibleElement } style={ { overflow: 'hidden' } }>
                                                <div className="login-section feature-box">
                                                    <div className="feature-box-content">
                                                        <form action="#" id="login-form">
                                                            <p className="ls-0">
                                                                If you have shopped with us before, please enter your details below. If you are
                                                                a new customer, please proceed to the Billing & Shipping section.
                                                </p>

                                                            {/* <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="mb-0 pb-1">Username or email <span
                                                                            className="required">*</span></label>
                                                                        <input type="email" className="form-control" required />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label className="mb-0 pb-1">Password <span
                                                                            className="required">*</span></label>
                                                                        <input type="password" className="form-control" required />
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            {/* <button type="submit" className="btn">LOGIN</button>

                                                            <div className="form-footer mb-1">
                                                                <div className="custom-control custom-checkbox mb-0 mt-0">
                                                                    <input type="checkbox" className="custom-control-input" id="lost-password" />
                                                                    <label className="custom-control-label mb-0" htmlFor="lost-password">Remember
                                                        me</label>
                                                                </div>

                                                                <ALink href="forgot-password" name="password" className="forget-password ls-0">Lost your password?</ALink >
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </h4>
                                    ) }
                                </SlideToggle >
                            </div>}  */}
                            
                            {/* <div className="checkout-discount">
                                <SlideToggle duration={ 200 } collapsed >
                                    { ( { onToggle, setCollapsibleElement, toggleState } ) => (
                                        <h4>Have a coupon? <button className="btn btn-link btn-toggle" onClick={ onToggle }>ENTER YOUR CODE</button>
                                            <div className="feature-box feature-coupon" ref={ setCollapsibleElement } style={ { overflow: 'hidden' } }>
                                                <div className="feature-box-content">
                                                    <p className="ls-0">If you have a coupon code, please apply it below.</p>

                                                    <form action="#">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control htmlForm-control-sm w-auto"
                                                                placeholder="Coupon code" required />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-sm mt-0" type="submit">
                                                                    Apply Coupon
                                            </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </h4>
                                    ) }
                                </SlideToggle >
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <ul className="checkout-steps">
                                        <li>
                                            <h2 className="step-title">Shipping details</h2>

                                            <form action="#" id="checkout-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>First name <abbr className="required" >*</abbr>
                                                            </label>
                                                            <input type="text" name='firstName'onChange={FormDataFunction}  className="form-control" required />
                                                            {validationErrors.firstName&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.firstName}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Last name <abbr className="required" >*</abbr></label>
                                                            <input type="text" name='lastName'onChange={FormDataFunction}  className="form-control" required />
                                                            {validationErrors.lastName&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.lastName}
                                                                </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label>Company name </label>
                                                    <input type="text" name='companyName'onChange={FormDataFunction} required className="form-control" />
                                                    {validationErrors.companyName&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.companyName}
                                                                </small>
                                                            )}
                                                </div>

                                                {/* <div className="select-custom">
                                                    <label>Country / Region <abbr className="required" title="required">*</abbr></label>
                                                    <select name="orderby" className="form-control">
                                                        <option value="" defaultValue="selected">Vanuatu
                                            </option>
                                                        <option value="1">Brunei</option>
                                                        <option value="2">Bulgaria</option>
                                                        <option value="3">Burkina Faso</option>
                                                        <option value="4">Burundi</option>
                                                        <option value="5">Cameroon</option>
                                                    </select>
                                                </div> */}

                                                <div className="form-group mb-1 pb-2">
                                                    <label>Street address <abbr className="required" >*</abbr></label>
                                                    <input type="text" className="form-control" name='shipping_street'onChange={FormDataFunction}
                                                        placeholder="House number and street name" required />
                                                         {validationErrors.shipping_street&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_street}
                                                                </small>
                                                            )}
                                                </div>

                                                <div className="form-group">
                                                    <input type="text" className="form-control"  name='shipping_street2' onChange={FormDataFunction}
                                                        placeholder="Apartment, suite, unite, etc. (optional)"  />
                                                         {/* {validationErrors.shipping_street2&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_street2}
                                                                </small>
                                                            )} */}
                                                </div>

                                                <div className="form-group">
                                                    <label>Town / City <abbr className="required" >*</abbr></label>
                                                    <input type="text" className="form-control"  name='shipping_city'onChange={FormDataFunction} required />
                                                    {validationErrors.shipping_city&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_city}
                                                                </small>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label> State<abbr className="required" >*</abbr></label>
                                                    <input type="text" className="form-control"  name='shipping_state' onChange={FormDataFunction} required />
                                                    {validationErrors.shipping_state&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_state}
                                                                </small>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label>Country<abbr className="required" >*</abbr></label>
                                                    <input type="text" className="form-control"  name='shipping_country'onChange={FormDataFunction} required />
                                                    {validationErrors.shipping_country&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_country}
                                                                </small>
                                                    )}
                                                </div>

                                                {/* <div className="select-custom">
                                                    <label>State / County <abbr className="required" title="required">*</abbr></label>
                                                    <select name="orderby" className="form-control">
                                                        <option value="" defaultValue="selected">NY</option>
                                                        <option value="1">Brunei</option>
                                                        <option value="2">Bulgaria</option>
                                                        <option value="3">Burkina Faso</option>
                                                        <option value="4">Burundi</option>
                                                        <option value="5">Cameroon</option>
                                                    </select>
                                                </div> */}

                                                <div className="form-group">
                                                    <label>Postcode / Zip <abbr className="required" >*</abbr></label>
                                                    <input type="text" className="form-control" name='shipping_postalCode'onChange={FormDataFunction} required />
                                                    {validationErrors.shipping_postalCode&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.shipping_postalCode}
                                                                </small>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Phone <abbr className="required" >*</abbr></label>
                                                    <input type="tel" className="form-control" name='phoneNumber'onChange={FormDataFunction} required />
                                                    {validationErrors.phoneNumber&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.phoneNumber}
                                                                </small>
                                                    )}
                                                </div>

                                                <div className="form-group">
                                                    <label>Email address <abbr className="required" >*</abbr></label>
                                                    <input type="email" className="form-control" name='email' onChange={FormDataFunction} required />
                                                    {validationErrors.email&&(
                                                                <small className='text-danger'>
                                                                    {validationErrors.email}
                                                                </small>
                                                    )}
                                                </div>
                                                <SlideToggle duration={ 200 } collapsed >
                                                    { ( { onToggle, setCollapsibleElement } ) => (
                                                        <div className="form-group mb-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id="create-account" onChange={ onToggle } />
                                                                <label className="custom-control-label" htmlFor="create-account">Create an account?</label>
                                                            </div>
                                                            <div className="form-group" ref={ setCollapsibleElement } style={ { overflow: 'hidden' } }>
                                                                <label className="mt-14">Create account password <abbr className="required" title="required">*</abbr></label>
                                                                <input type="password"  name='password' placeholder="Password" className="form-control"
                                                                    required />
                                                            </div>
                                                        </div>
                                                    ) }
                                                </SlideToggle >
                                                <SlideToggle duration={ 300 } collapsed >
                                                    { ( { onToggle, setCollapsibleElement } ) => (
                                                        <div className="form-group mb-11">
                                                            <div className="custom-control custom-checkbox mt-0 address-box">
                                                                <input type="checkbox" className="custom-control-input"
                                                                    id="different-shipping" onChange={ onToggle } />
                                                                <label className="custom-control-label" htmlFor="different-shipping">Bill to a different address?
                                                    </label>
                                                            </div>
                                                            <div className="shipping-info" ref={ setCollapsibleElement } style={ { overflow: 'hidden' } }>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>First name <abbr className="required"
                                                                                >*</abbr></label>
                                                                            <input type="text" className="form-control"  name='firstName'onChange={FormDataFunction} required />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>Last name <abbr className="required"
                                                                                >*</abbr></label>
                                                                            <input type="text" className="form-control" name='lastName'onChange={FormDataFunction} required />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Company name (optional)</label>
                                                                    <input type="text" className="form-control" name='companyName'onChange={FormDataFunction} />
                                                                </div>
{/* 
                                                                <div className="select-custom">
                                                                    <label>Country / Region <span className="required">*</span></label>
                                                                    <select name="orderby" className="form-control">
                                                                        <option value="" defaultValue="selected">Vanuatu</option>
                                                                        <option value="1">Brunei</option>
                                                                        <option value="2">Bulgaria</option>
                                                                        <option value="3">Burkina Faso</option>
                                                                        <option value="4">Burundi</option>
                                                                        <option value="5">Cameroon</option>
                                                                    </select>
                                                                </div> */}

                                                                <div className="form-group mb-1 pb-2">
                                                                    <label>Street address <abbr className="required"
                                                                       >*</abbr></label>
                                                                    <input type="text" className="form-control"
                                                                        placeholder="House number and street name"  name='billing_stree'onChange={FormDataFunction} required />
                                                                </div>

                                                                <div className="form-group">
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Apartment, suite, unit, etc. (optional)"  name='billing_stree2'onChange={FormDataFunction} required />
                                                                </div>

                                                                <div className="form-group">
                                                                    <label>Town / City <abbr className="required"
                                                                        >*</abbr></label>
                                                                    <input type="text" className="form-control" name='billing_city'onChange={FormDataFunction} required />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>State <abbr className="required"
                                                                        >*</abbr></label>
                                                                    <input type="text" className="form-control" name='billing_state'onChange={FormDataFunction} required />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label>Country <abbr className="required"
                                                                        >*</abbr></label>
                                                                    <input type="text" className="form-control" name='billing_country'onChange={FormDataFunction} required />
                                                                </div>

                                                                {/* <div className="select-custom">
                                                                    <label>State / County <abbr className="required"
                                                                        title="required">*</abbr></label>
                                                                    <select name="orderby" className="form-control">
                                                                        <option value="" defaultValue="selected">NY</option>
                                                                        <option value="1">Brunei</option>
                                                                        <option value="2">Bulgaria</option>
                                                                        <option value="3">Burkina Faso</option>
                                                                        <option value="4">Burundi</option>
                                                                        <option value="5">Cameroon</option>
                                                                    </select>
                                                                </div> */}

                                                                <div className="form-group">
                                                                    <label>Postcode / ZIP <abbr className="required"
                                                                        >*</abbr></label>
                                                                    <input type="text" className="form-control" name='billing_postalCode'onChange={FormDataFunction} required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) }
                                                </SlideToggle >

                                                <div className="form-group">
                                                    <label className="order-comments">Order notes (optional)</label>
                                                    <textarea className="form-control"
                                                        placeholder="Notes about your order, e.g. special notes for delivery."
                                                        ></textarea>
                                                </div>
                                            </form>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-lg-5">
                                    <div className="order-summary">
                                        <h3>YOUR ORDER</h3>

                                        <table className="table table-mini-cart">
                                            <thead>
                                                <tr>
                                                    <th colSpan="2">Product</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cartList.map( ( item, index ) => (
                                                        <tr key={ "checks" + index }>
                                                            <td className="product-col">
                                                                <h2 className="product-title">
                                                                    { item.name + '×' + item.qty }
                                                                </h2>
                                                            </td>

                                                            <td className="price-col">
                                                                <span>₹{ ( item?.variants?.option_price * item.qty ).toFixed( 2 ) }</span>
                                                            </td>
                                                        </tr>
                                                    ) )
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr className="cart-subtotal">
                                                    <td>
                                                        <h4>Subtotal</h4>
                                                    </td>

                                                    <td className="price-col">
                                                        <span>₹{ getCartTotal( cartList ).toFixed( 2 ) }</span>
                                                    </td>
                                                </tr>
                                                {/* <tr className="order-shipping">
                                                    <td className="text-left" colSpan="2">
                                                        <h4 className="m-b-sm">Shipping</h4>
                                                        <div className="form-group form-group-custom-control">
                                                            <div className="custom-control custom-radio d-flex">
                                                                <input type="radio" className="custom-control-input" name="radio"
                                                                    defaultChecked />
                                                                <label className="custom-control-label">Local Pickup</label>
                                                            </div>
                                                        </div>

                                                        <div className="form-group form-group-custom-control mb-0">
                                                            <div className="custom-control custom-radio mb-0 d-flex">
                                                                <input type="radio" name="radio" className="custom-control-input" />
                                                                <label className="custom-control-label">Flat Rate</label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr> */}

                                                <tr className="order-total">
                                                    <td>
                                                        <h4>Total</h4>
                                                    </td>
                                                    <td>
                                                        <b className="total-price"><span>₹{ getCartTotal( cartList ).toFixed( 2 ) }</span></b>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="payment-methods">
                                            <h4 className="">Payment methods</h4>
                                            <div className="info-box with-icon p-0">
                                                 <ul className='m-0'>
                                                <li>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="paymentGateway"
                                                            value="Cash On Delivery"
                                                            checked={paymentMethod === 'Cash On Delivery'}
                                                            onChange={handlePaymentChange}
                                                            required
                                                        />
                                                        {"  "}Cash on Delivery
                                                    </label>
                                                </li>
                                                <li>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name="paymentGateway"
                                                            value="Razorpay"
                                                            checked={paymentMethod === 'Razorpay'}
                                                            onChange={handlePaymentChange}
                                                        />
                                                        {"  "}Razor Pay
                                                    </label>
                                                </li>
                                                </ul>
                                            </div>

                                        </div>
                                       {showPayment && 
                                   (  <Payment orderGuid={orderGuid} order_amount={subtotal} order_id={orderId} currency={orderCurrency} name={"Naren"} email={"naren@yopmail.com"} contact={452423453} />)}
                                    <button type="submit" onClick={() => { onClickPlaceOrder() }} value="Place Order" name="form-control" className="btn btn-dark btn-place-order">
                                        PLACE ORDER
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
            {loading && (
                <div className="loader-overlay">
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                        <span style={{marginTop:"10px"}}>Loading</span>
                    </Box>
                </div>
            )}

            <style jsx>{`
                .loader-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
            `}</style>
        </main>
    )
}
export default CheckOut;