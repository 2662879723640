import { useEffect, useState } from 'react';
import React from 'react';
import ProductOne from '../../features/products/product-one';

export default function ProductsGrid(props) {
    let { products = [], gridClass = "col-6 col-sm-4", perPage, addClass = '' } = props;
    const [loading ,setLoading]=useState(true);
    
    useEffect(() => {
        // Set loading to false after a delay if there are products, else keep it true to show skeleton
        if (products.length > 0) {
            setLoading(false);
        } else {
            const timer = setTimeout(() => setLoading(false), 2000); // Adjust the timeout as needed
            return () => clearTimeout(timer); // Clean up the timer on unmount
        }
    }, [products]);

    return (
        <>
            <div className={`row skeleton-body skel-shop-products ${addClass} ${!loading ? 'loaded' : ''}`}>
                {
                    loading ?
                        new Array(parseInt(perPage)).fill(1).map((item, index) =>
                            <div className={gridClass} key={`skel-pro-${index}`}>
                                <div className="skel-pro skel-pro-grid"></div>
                            </div>
                        )
                        :
                        products.slice(0,perPage).map((item, index) => (
                            <div className={gridClass} key={`product-${index}`}>
                                <ProductOne product={item} />
                            </div>
                        ))
                      
                }
            </div>
            {
                !loading && products.length === 0 ?
                    <div className="info-box with-icon"><p>No products were found matching your selection.</p></div>
                    : ''
            }
        </>
    )
}